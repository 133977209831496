import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Container, Grid, Hidden, withStyles } from "@material-ui/core";
import ImageOne from "../../../assets/images/am2020.png";
import ImageTwo from "../../../assets/images/elle.png";
import ImageThree from "../../../assets/images/ehousing.png";
import Tobject from "../../../assets/images/tobject.gif";
import IdentityT from "../../../assets/images/identityTLast.svg";
import KnowMorebtn from "../../Common/Button/KnowButton";
import { styles } from "./styles";
import WOW from "wowjs";

class PressSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const { classes } = this.props;
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 3,
      speed: 500,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 3000,
      afterChange: this.handleAfterChange,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="py-70 object-section">
        <Container>
          <Hidden xsDown>
            <Grid container direction="row" justify="center">
              <Grid item sm={11}>
                <Grid container direction="row" justify="center" spacing={4}>
                  <Grid item sm={3}>
                    <div className="object-content">
                      <img src={ImageOne} alt="ImageOne" />
                      <div className="p-5">
                        {/* <p>
                          TOP 100 Interior <br /> Designers in the World
                        </p> */}
                        {/* <h6>2020</h6> */}
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={3}>
                    <div className="object-content">
                      <img src={ImageTwo} alt="ImageTwo" />
                      <div className="p-5">
                        {/* <p>
                          TOP 100 Interior <br /> Designers in the World
                        </p> */}
                        {/* <h6>2020</h6> */}
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={3}>
                    <div className={classes.inverted}>
                      <img
                        src={IdentityT}
                        alt="Tobject"
                        className={`${classes.topT} wow fadeInDown`}
                        data-wow-duration="2s"
                      />
                      <img
                        src={Tobject}
                        alt="Tobject"
                        className={classes.bottomT}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={3}>
                    <div className="object-content">
                      <img src={ImageThree} alt="ImageThree" />
                      <div className="p-5">
                        {/* <p>
                          TOP 100 Interior <br /> Designers in the World
                        </p> */}
                        {/* <h6>2021</h6> */}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <a href="/press">
              <div className="text-center">
                <KnowMorebtn />
              </div>
            </a>
          </Hidden>
          <Hidden smUp>
            <Slider ref={(c) => (this.slider = c)} {...settings}>
              <div className="object-content">
                <img src={ImageOne} alt="ImageOne" />
                <div className="p-5">
                  {/* <p>
                    TOP 100 Interior <br /> Designers in the World
                  </p> */}
                  {/* <h6>2020</h6> */}
                </div>
              </div>
              <div className="object-content">
                <img src={ImageTwo} alt="ImageTwo" />
                <div className="p-5">
                  {/* <p>
                    TOP 100 Interior <br /> Designers in the World
                  </p> */}
                  {/* <h6>2020</h6> */}
                </div>
              </div>
              <div className="object-content">
                <img src={ImageThree} alt="ImageThree" />
                <div className="p-5">
                  {/* <p>
                    TOP 100 Interior <br /> Designers in the World
                  </p> */}
                  {/* <h6>2021</h6> */}
                </div>
              </div>
            </Slider>
            <div className="text-center">{this.state.currentSlide + 1} / 3</div>

            <div className={classes.invertedMobile}>
              <img
                src={IdentityT}
                alt="Tobject"
                className={`${classes.topTMobile} wow fadeInDown`}
                data-wow-duration="2s"
              />
              <img
                src={Tobject}
                alt="Tobject"
                className={classes.bottomTMobile}
              />
            </div>
          </Hidden>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(PressSlider);
