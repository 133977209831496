import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  root: {
    marginTop: "0px",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 30,
    },
  },

  tabs: {
    marginTop: "120px",
  },

  tabBtn: {
    fontSize: "20px",
    color: theme.palette.other.black,
    lineHeight: "22px",
    textTransform: "uppercase",
    fontFamily: "Philosopher",
    textAlign: "left",
    maxWidth: "100%",
    fontWeight: "bold",
    marginBottom: "0px",
    marginTop: "0",
    paddingLeft: "0",
    padding: "4px 0",
    minHeight: "inherit",
    opacity: "1",
    justifyContent: "flex-start",
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "18px",
      marginBottom: "0px",
    },
    "& span": {
      display: "inline-block",
      width: "auto",
      position: "relative",

      "&:after": {
        width: "20px",
        backgroundColor: theme.palette.primary.blue,
        height: "20px",
        content: '""',
        position: "absolute",
        right: "25px",
        top: "-3px",
        borderRadius: "50%",
        opacity: "0",
      },
    },
    "&:hover": {
      "& span": {
        "&:after": {
          opacity: "1",
        },
      },
    },

    "&$selected": {
      backgroundColor: "#004C9B",
      color: "white",
    },

    indicator: {
      backgroundColor: "#004C9B",
    },
  },

  padding: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
  },
  p: {
    fontFamily: "Philosopher",
    color: theme.palette.other.black,
    lineHeight: "13px",
    fontSize: "14px",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "12px",
      paddingLeft: "15px",
      paddingRight: "15px",
      textAlign: "justify",
      textAlignLast: "center",
    },
  },

  tabBtnMobile: {
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Philosopher",
  },
  AccordionSummary: {
    minHeight: "inherit",
    "& div": {
      marginTop: 2,
      marginBottom: 2,
    },
  },
  tabsThree: {
    "& imagea": {
      display: "block",
      margin: " auto",
      marginBottom: "-50px",
    },
    "& imageb": {
      marginTop: " -250px",
      position: "relative",
    },
    "& imagec": {
      top: " -90px",
      position: "relative",
      left: "80px",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
      },
    },

    "& img": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "50%",
      },
    },
  },
}));
