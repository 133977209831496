import { makeStyles } from "@material-ui/core/styles";
import backImg from "../../../assets/images/press/contactImg.png";
export const styles = makeStyles((theme) => ({
  contactImageContainer: {
    backgroundImage: `url(${backImg})`,
    backgroundSize: "cover",
    height: 550,
    width: "100%",
     [theme.breakpoints.down("sm")]: {
      height: 450,
    },
  },
  text: {
    height: "100%",
    color: theme.palette.other.white,
    display: "flex",
    alignItems: "center",
    fontSize: 30,
    lineHeight: "35px",
    textTransform: "uppercase",
    maxWidth: 790,
    paddingLeft:'15px',
    paddingRight:'15px',
    left: "0",
    right: "0",
    margin: "auto",
    position: "relative",
    fontFamily: "Philosopher",
    fontWeight: 400,
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      fontSize: 21,
      padding: 15,
      lineHeight: "21px",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      left: 0,
    },
  },
  arrow: {
    width: 70,
    marginTop: 50,
    filter: "invert(1)",
    [theme.breakpoints.down("sm")]: {
      width: 50,
    },
  },
}));
