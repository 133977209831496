import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { withStyles } from "@material-ui/core";

import { styles } from "./styles";
class LogoCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const { images } = this.props;
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 1,
      speed: 500,
      arrows: false,
      dots: false,
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 50,
      fade: true,
    };

    return (
      <div className="logo-section">
        <a href="/">
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {images.map(({ id, src }) => {
              return <img key={id} src={src} alt="logo" />;
            })}
          </Slider>
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(LogoCarousel);
