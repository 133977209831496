export const widthValues = ["xs", "sm", "md", "lg"];
export const SCREEN_WIDTHS = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
};
export const SPACE_TYPES = {
  TOP_CONTENT: 1,
  BOTTOM_CONTENT: 2,
};
