import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Container, Grid } from "@material-ui/core";
import Object1 from "../../../assets/images/object1.png";
import Object2 from "../../../assets/images/object2.png";
import Object3 from "../../../assets/images/object5.jpg";
import Object4 from "../../../assets/images/object4.png";
import BarberShop from "../../../assets/images/barber-shop.png";
import WOW from "wowjs";

const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  fade: true,
  arrows: false,
  dots: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ObjectCarousel = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className="barber-slider">
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={10} xs={12}>
            <Grid
              container
              direction="row-reverse"
              justify="center"
              alignItems="center"
            >
              <Grid item sm={6} xs={8}>
                <h2 className="main-heading heading-minus-top">objects</h2>
              </Grid>
              <Grid
                item
                xs={8}
                sm={5}
                md={6}
                className="wow fadeInDown"
                data-wow-duration="2s"
                data-wow-delay=".1s"
              >
                <img src={BarberShop} alt="Barber" />
              </Grid>
            </Grid>
            <Slider {...settings} className="barder-top">
              <div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item sm={4} md={4}>
                    <div className="barber-content">
                      <h4>YOU ARE THE KING IN MY STORY!</h4>
                      <p>OBJECT/SCULPTURE </p>
                      <p>MATERIAL/FIBER AND STAINLESS STEEL</p>
                    </div>
                  </Grid>
                  <Grid item sm={8} md={8}>
                    <img src={Object4} alt="Object4" />
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item sm={4} md={4}>
                    <div className="barber-content">
                      <h4>PARCEL ME COFFEE SERIES</h4>
                      <p>OBJECT/COFFEE TABLE</p>
                      <p>PEDESTAL MATERIAL/STAINLESS STEEL, BRASS & GLASS</p>
                    </div>
                  </Grid>
                  <Grid item sm={8} md={8}>
                    <img src={Object2} alt="Object2" />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item sm={4} md={4}>
                    <div className="barber-content">
                      <h4>Lock & Key</h4>
                      <p>OBJECT/CHAIR </p>
                      <p>MATERIAL/WOOD</p>
                    </div>
                  </Grid>
                  <Grid item sm={8} md={8}>
                    <img src={Object3} alt="Object3" />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item sm={4} md={4}>
                    <div className="barber-content">
                      <h4>TIME TO REFLECT SERIES</h4>
                      <p>OBJECT/COFFEE TABLE </p>
                      <p>MATERIAL/STAINLESS STEEL, BRASS & GLASS</p>
                    </div>
                  </Grid>
                  <Grid item sm={8} md={8}>
                    <img src={Object1} alt="Object1" />
                  </Grid>
                </Grid>
              </div>
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default ObjectCarousel;
