import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  withWidth,
  Grid,
  Container,
  Hidden,
} from "@material-ui/core";
import Ban1 from "../../../assets/images/ban-1.png";
import Ban2 from "../../../assets/images/ban-2.png";
import Ban3 from "../../../assets/images/ban-3.png";
import Ban4 from "../../../assets/images/ban-4.png";
import Ban5 from "../../../assets/images/ban-5.png";
import Bimage from "../../../assets/images/b-icon.png";
import Video from "../../../assets/video/HOMEPAGE_Video.mov";
import { styles as useStyles } from "./styles";
import LogoCarousel from "../../Common/LogoCarousel/LogoCarousel";
import barberShop from "../../../assets/images/barberShop.svg";
import identityTLogo from "../../../assets/images/identityTLogo.svg";
import scrollDown from "../../../assets/images/ScrollDown.png";

const homeImages = [
  { id: 1, src: identityTLogo },
  { id: 2, src: barberShop },
];
const BannerVideo = (props) => {
  const classes = useStyles();
  const [mute, setMute] = useState(true);
  const handleClick = () => {
    setMute(!mute);
  };
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div className={classes.mainBanner}>
      <div className={classes.mainBannerOverlay}>
        <LogoCarousel images={homeImages} />
      </div>
      <video
        playsInline
        loop
        muted={mute}
        src={Video}
        ref={videoEl}
        className={classes.video}
      />

      <img src={scrollDown} alt="scrolldown" className="ScrollDown" />
      <div className="bottom-left" onClick={handleClick}>
        <Typography className={classes.playButton}>
          <span className={mute ? classes.mute : classes.unmute}>play</span>
        </Typography>
      </div>
      <div className={classes.HeadBottom}>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            spacing={2}
          >
            <Hidden smDown>
              <Grid item md={6}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item md={6}>
                    <Typography className={classes.p} component="div">
                      <img src={Bimage} alt="Bimage3" className={classes.Pl2} />
                      TOP 100 Interior Designers in the World | Andrew Martin
                      interior Design Review
                    </Typography>
                  </Grid>
                  <Grid item md={6} className={classes.griditem}>
                    <Typography className={classes.p} component="div">
                      We are a New Delhi-based but globally minded design
                      consultancy dedicated to luxury spaces
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item md={6}>
              <div className={classes.DFlex}>
                <div>
                  <img src={Ban1} alt="Ban1" />
                </div>
                <div>
                  <img src={Ban2} alt="Ban2" />
                </div>
                <div>
                  <img src={Ban3} alt="Ban3" />
                </div>
                <div>
                  <img src={Ban4} alt="Ban4" />
                </div>
                <div>
                  <img src={Ban5} alt="Ban5" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default withWidth()(BannerVideo);
