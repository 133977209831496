import Logo from "../../../assets/images/footer-logo.png";
import { styles as useStyles } from "./styles";
const SanjaytLogo = () => {
  const classes = useStyles();
  return (
    <a href="/">
      <img src={Logo} alt="Logo" className={classes.logo} />
    </a>
  );
};
export default SanjaytLogo;
