import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";

export const styles = makeStyles((theme) => ({
  mainBanner: {
    position: "relative",
    marginTop: "36px",
  },
  P0: {
    marginBottom:'60px',
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },

  link: {
    display: "inline-block",
    paddingLeft: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "130px",
    },
  },
  HeadRight: {
    height: "100%",
    position: "absolute",
    right: "0",
    top: "0",
    bottom: "0",
    width: "100px",
    backgroundColor: "#171717",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "80px",
    },
  },
  Tlogo: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "100%",
  },
  DFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Pl2: {
    paddingRight: "10px",
  },
  para: {
    fontFamily: "Philosopher",
    color: theme.palette.other.white,
    fontSize: "11px",
    opacity: "0.6",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    transform: "rotate(90deg)",
    width: "310px",
    height: "auto",
    left: "-100px",
    position: "absolute",
    top: "190px",
    [theme.breakpoints.down("xs")]: {
      top: "160px",
      left: "-115px",
    },
  },
  relative: {
    position: "relative",
    //height: "100%",
    display: "flex",
    flexDirection: "column",
    height: "500px",
  },
  imgobject: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
  },
  LeftBottom: {
    position: "absolute",
    left: "50px",
    bottom: "30px",
    maxWidth: "305px",
  },
  Pbottom: {
    fontSize: "14px",
    fontFamily: "Philosopher",
    color: theme.palette.other.white,
    lineHeight: "13px",
  },
}));
