import React from "react";
import { Typography, withWidth, Container } from "@material-ui/core";
import Feed from "../InstagarmWidget/Feed";
import { styles as useStyles } from "./styles";

const Insta = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root + " insta-bg"}>
      <Container>
        <div className="Dflex">
          <Typography className={classes.h2} variant="h2">
            visit our instagram
          </Typography>
          <Feed />
        </div>
      </Container>
    </div>
  );
};
export default withWidth()(Insta);
