import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  mainBanner: {
    position: "relative",
    marginTop: "30px",
  },
  mainBannerOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  videoCard: {
    boxShadow: "none",
    borderRadius: 0,
    position: "relative",
  },
  video: {
    width: "100%",
    height: "calc(100vh - 30px)",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      height: "80vh",
      objectFit: "cover",
    },
    "& video": {
      objectFit: "cover",
    },
  },

  link: {
    display: "inline-block",
    paddingLeft: "15px",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "130px",
    },
  },
  HeadBottom: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: theme.palette.other.black,
    padding: "20px 20px 20px 20px",
  },
  DFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Pl2: {
    paddingRight: "10px",
  },
  p: {
    fontFamily: "Philosopher",
    color: theme.palette.other.white,
    fontSize: "11px",
    opacity: "0.6",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
  },
  videoImg: {
    position: "absolute",
    zIndex: 0,
    top: 0,
    bottom: "0",
    left: 0,
    right: 0,
    width: "100vw",
    height: "100%",
    objectFit: "cover",
  },
  playButton: {
    color: "#ffffff",
    fontSize: "13px",
    fontWeight: "400",
    fontFamily: "Be Vietnam",
    letterSpacing: "8px",
  },
  griditem: {
    padding: "6px !important",
  },
  mute: {
    textDecoration: "line-through",
  },
  unmute: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "line-through",
    },
  },
}));
