import React, { useEffect, useRef } from "react";
import { Typography, withWidth, Grid, Container } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import identityTLogoW from "../../../assets/images/identityTLogo.svg";
const FullWidthImage = ({ item: { videoUrl } }) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <Container className={classes.P0}>
      {videoUrl && (
        <Grid container direction="row" justify="center">
          <Grid item md={12}>
            <div className={classes.mainBanner}>
              <Grid container direction="row" justify="center">
                <Grid item md={11}>
                  <div className={classes.relative}>
                    <video
                      playsInline
                      loop
                      autoPlay
                      muted
                      src={videoUrl}
                      ref={videoEl}
                      className={classes.imgobject}
                    />
                    <div className={classes.HeadRight}>
                      <Typography className={classes.para} component="div">
                        We are a New Delhi-based but globally minded design
                        consultancy dedicated to luxury spaces
                      </Typography>
                      <img
                        src={identityTLogoW}
                        alt=""
                        className={classes.Tlogo}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
export default withWidth()(FullWidthImage);
