import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({

  pressItem: {
    cursor: 'pointer',
    width: "100%",
    height: 'auto',
    marginBottom:'50px',
     [theme.breakpoints.down('xs')]: {
      marginBottom:'20px',
    },
    "& img":{
      width:"100%",
       transition: "all linear 0.2s",
  cursor: "pointer",
    },
    "&:hover":{
      color: theme.palette.primary.blue,
       "& img":{
      transform: "scale(1.03)",
    },

    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  pressText: {
    fontSize: 13,
    textTransform: 'uppercase',
    marginBottom: 20,
    marginTop:'5px',
    lineHeight:'13px',
  },
  videoBox: {
    position: 'relative',
  },
  dot: {
    width: 22,
    height: 22,
    backgroundColor: theme.palette.other.dotBlue,
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: 7,
    '-webkit-animation': 'blink 1.7s infinite',
    '-moz-animation': 'blink 1.7s infinite',
    '-o-animation': 'blink 1.7s infinite',
    animation: 'blink 1.7s infinite',
    position: 'absolute',
    top: 40,
    left: 40,
  },
  owl: {
    width: "100%",
    marginTop:'-10px',
    [theme.breakpoints.down('xs')]: {
    marginTop:'0px',
    },
  },
  textX:{
    fontSize:'37px',
    color:'#93875f',
    textAlign:"center",
    fontFamily: 'Freigeist-WideRegular',
    marginBottom:'15px',
    marginTop:'10px',
    [theme.breakpoints.down('xs')]: {
      fontSize:'20px',
       marginBottom:'0px',
    marginTop:'0px',
    lineHeight:'18px',
    },
  },
  textName:{
    textAlign:'center',
    fontSize:'18px',
    fontFamily: 'Freigeist-WideRegular',
    textTransform:'uppercase',
    letterSpacing:'2px',
    lineHeight:'18px',
    color:theme.palette.other.black,
    [theme.breakpoints.down('xs')]: {
     fontSize:'9px',
      lineHeight:'9px',
      letterSpacing:'1px',
    },
  }

}));
