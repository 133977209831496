import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  arrow: {
    backgroundColor: theme.palette.primary.blue,
    padding: "7px 15px",
    display: "inline-block",
    alignItems: "center",
    marginBottom: "10px",
    opacity: 0,
    transition: "all ease 0.2s",
    float: "right",
  },
  spaceItem: {
    cursor: "pointer",
    width: "100%",
    height: "100%",
    position: "relative",
    marginBottom: "0px",
    overflow: "hidden",
    "& img": {
      width: "100%",
      transition: "all linear 0.2s",
      cursor: "pointer",
      height: "100%",
    },

    "&:hover": {
      color: theme.palette.primary.blue,
      "& img": {
        transform: "scale(1.03)",
      },
      "& div": {
        opacity: "1",
      },
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  spaceItemOver: {
    overflow: "visible",
  },
  widthAuto: {
    width: "100% !important",
    height: "auto !important",
    maxWidth: "300px",
    position: "absolute",
    left: "0",
    top: "0",
    zIndex: "0",
    transform: "scale(1) !important",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "30vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60vw",
    },
  },
  imgRound: {
    zIndex: "1",
    height: "auto !important",
    position: "relative",
    right: "-50px",
    maxWidth: "95%",
    top: "10px",
    transform: "scale(1) !important",
    [theme.breakpoints.down("sm")]: {
      right: "0px",
      maxWidth: "100%",
    },
  },
  overlay: {
    position: "absolute",
    bottom: "10px",
    fontWeight: "600",
    right: "20px",
  },

  arrowRight: {
    filter: "invert(10000%)",
    width: "50px !important",
    [theme.breakpoints.down("xs")]: {
      width: "40px !important",
    },
  },
  spaceText: {
    fontSize: 14,
    textTransform: "uppercase",
    marginBottom: 15,
    marginTop: "5px",
    lineHeight: "13px",
    textAlign: "right",
    clear: "both",
    float: "left",
    color: theme.palette.other.white,
  },
  overlayHeading: {
    textTransform: "uppercase",
    position: "absolute",
    top: "10px",
    left: "10px",
    right: "10px",
    textAlign: "center",
  },
  h2: {
    fontSize: "50px",
    color: theme.palette.other.white,
    fontFamily: "Staatliches",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
  videoBox: {
    position: "relative",
  },
  dot: {
    width: 22,
    height: 22,
    backgroundColor: theme.palette.other.dotBlue,
    display: "inline-block",
    borderRadius: "50%",
    marginRight: 7,
    "-webkit-animation": "blink 1.7s infinite",
    "-moz-animation": "blink 1.7s infinite",
    "-o-animation": "blink 1.7s infinite",
    animation: "blink 1.7s infinite",
    position: "absolute",
    top: 40,
    right: 40,
  },
  owl: {
    width: "100%",
  },
}));
