import object1 from "../assets/images/objects/object1/1.jpg";
import object2 from "../assets/images/objects/object2/2.jpg";
import object2hover from "../assets/images/objects/1.png";
import object3 from "../assets/images/objects/object3/3.jpg";
import object4 from "../assets/images/objects/object4/4.jpg";
import object5 from "../assets/images/objects/object5/5.jpg";
import object6 from "../assets/images/objects/object6/6.jpg";
import object7 from "../assets/images/objects/object7/7.gif";
import press1 from "../assets/images/press/1/1.png";
import press2 from "../assets/images/press/2/2.png";
import press3 from "../assets/images/press/3/3.png";
import press4 from "../assets/images/press/4/4.png";
import press5 from "../assets/images/press/5/5.png";
import press6 from "../assets/images/press/6/6.png";
import press7 from "../assets/images/press/7/7.png";
import press8 from "../assets/images/press/8/8.png";
import press9 from "../assets/images/press/9/9.png";
import press10 from "../assets/images/press/10/10.png";
import press11 from "../assets/images/press/11/11.png";
import press12 from "../assets/images/press/12/12.png";
import press13 from "../assets/images/press/13/13.png";
import press14 from "../assets/images/press/14/14.png";
import press15 from "../assets/images/press/15/15.png";
import press16 from "../assets/images/press/16/16.png";
import press17 from "../assets/images/press/17/17.png";
import press18 from "../assets/images/press/18/18.png";
import press19 from "../assets/images/press/19/19.png";
import press20 from "../assets/images/press/20/20.png";
import press21 from "../assets/images/press/21/21.png";
import press22 from "../assets/images/press/22/22.png";
import press23 from "../assets/images/press/23/23.png";
import press24 from "../assets/images/press/24/24.jpg";
import press25 from "../assets/images/press/25/25.png";
import press26 from "../assets/images/press/26/26.png";
import press27 from "../assets/images/press/27/27.png";
import press28 from "../assets/images/press/28/28.png";
import press29 from "../assets/images/press/29/29.png";
import press30 from "../assets/images/press/30/30.png";
import press31 from "../assets/images/press/31/31.png";
import press32 from "../assets/images/press/32/32.png";
import press33 from "../assets/images/press/33/33.png";
import press34 from "../assets/images/press/34/34.png";
import press35 from "../assets/images/press/35/35.png";
import space1 from "../assets/images/space/EpiphanyBlue.jpg";
import space2 from "../assets/images/space/Chalet.jpg";
import space3 from "../assets/images/space/BespokePenthouse.jpg";
import space4 from "../assets/images/space/GreenPark.jpg";
import space5 from "../assets/images/space/Villa.jpg";
import space6 from "../assets/images/space/Eclectic.mov";
import space7 from "../assets/images/space/LuxuryCorporateOffice.jpg";
import space8 from "../assets/images/space/LuxuryPenthouse.jpg";
import space9 from "../assets/images/space/BespokeLuxuryOffice.jpg";
import space10 from "../assets/images/space/GoldsGym.jpg";
import space11 from "../assets/images/space/PlushOffice.jpg";
import space12 from "../assets/images/space/ChristmasStyling.jpg";
import space13 from "../assets/images/space/DesignStudio.jpg";
import space14 from "../assets/images/space/CorporateOffice.jpg";
import space15 from "../assets/images/space/WeekendHome.mov";
import space16 from "../assets/images/space/GoldsGym43.mov";
import space17 from "../assets/images/space/HousewarmingMumbai.jpg";
import space18 from "../assets/images/space/LuxuryOffice.jpg";
import space19 from "../assets/images/space/IndiaDesignID.jpg";
import space20 from "../assets/images/space/RestoBar.jpg";
import collage1 from "../assets/images/space/epiphanyblue/collage-epiphany.gif";
import collage3 from "../assets/images/space/BespokePenthouse/collage.png";
import collage4 from "../assets/images/space/greenpark/collage.png";
import collage5 from "../assets/images/space/dubaivilla/collage.gif";
import collage6 from "../assets/images/space/eclecticapartment/collage.gif";
import collage7 from "../assets/images/space/luxuryCorporate/collage.jpg";
import collage8 from "../assets/images/space/luxurypenthouse/collage.jpg";
import collage9 from "../assets/images/space/LuxuryOfficeBKCMumbai/collage.jpg";
import collage10 from "../assets/images/space/goldgym14/collage.jpg";
import collage11 from "../assets/images/space/plushoffice/collage.gif";
import collage12 from "../assets/images/space/christmasstyling/collage.gif";
import collage13 from "../assets/images/space/designstudio/collage.jpg";
import collage14 from "../assets/images/space/corporateOffice/collage.jpg";
import collage15 from "../assets/images/space/weekendhouse/collage.jpg";
import collage16 from "../assets/images/space/goldgym43/collage.jpg";
import collage17 from "../assets/images/space/Housewarming/collage.jpg";
import collage18 from "../assets/images/space/luxuryofficeBKC/collage.jpg";
import collage19 from "../assets/images/space/IndiaDesignID/collage.gif";
import collage20 from "../assets/images/space/restrobar/collage.jpg";
import mainvideo2 from "../assets/images/space/chalet/video.mp4";
import mainvideo3 from "../assets/images/space/BespokePenthouse/video.mp4";
import mainvideo7 from "../assets/images/space/luxuryCorporate/video.mp4";
import mainvideo8 from "../assets/images/space/luxurypenthouse/video.mp4";
import mainvideo9 from "../assets/images/space/LuxuryOfficeBKCMumbai/video.mp4";
import mainvideo10 from "../assets/images/space/goldgym14/video.mp4";
import mainvideo11 from "../assets/images/space/plushoffice/video.mp4";
import mainvideo13 from "../assets/images/space/designstudio/video.mp4";
import mainvideo14 from "../assets/images/space/corporateOffice/video.mp4";
import mainvideo16 from "../assets/images/space/goldgym43/video.mp4";
import mainvideo17 from "../assets/images/space/Housewarming/video.mp4";
import mainvideo18 from "../assets/images/space/luxuryofficeBKC/video.mp4";
import featuredImage1 from "../assets/images/space/epiphanyblue/feature.jpg";
import featuredImage3 from "../assets/images/space/BespokePenthouse/feature.jpg";
import featuredImage4 from "../assets/images/space/greenpark/BachelorApartment.png";
import featuredImage5 from "../assets/images/space/dubaivilla/feature.jpg";
import featuredImage6 from "../assets/images/space/eclecticapartment/feature.jpg";
import featuredImage7 from "../assets/images/space/luxuryCorporate/feature.jpg";
import featuredImage8 from "../assets/images/space/luxurypenthouse/feature.jpg";
import featuredImage9 from "../assets/images/space/LuxuryOfficeBKCMumbai/feature.jpg";
import featuredImage10 from "../assets/images/space/goldgym14/feature.jpg";
import featuredImage12 from "../assets/images/space/christmasstyling/feature.jpg";
import featuredImage13 from "../assets/images/space/designstudio/feature.jpg";
import featuredImage14 from "../assets/images/space/corporateOffice/feature.jpg";
import featuredImage15 from "../assets/images/space/weekendhouse/feature.jpg";
import featuredImage16 from "../assets/images/space/goldgym43/feature.jpg";
import featuredImage17 from "../assets/images/space/Housewarming/feature.jpg";
import featuredImage18 from "../assets/images/space/luxuryofficeBKC/feature.jpg";
import featuredImage19 from "../assets/images/space/IndiaDesignID/feature.jpg";
import featuredImage20 from "../assets/images/space/restrobar/feature.jpg";
import banner1 from "../assets/images/press/banner1.jpeg";
import spacebanner1 from "../assets/images/space/banner1.JPG";
import banner2 from "../assets/images/press/banner2.jpeg";

//Scroll Space images//

import scroll1 from "../assets/images/space/epiphanyblue/scrollimages/1.jpg";
import scroll2 from "../assets/images/space/epiphanyblue/scrollimages/2.jpg";
import scroll3 from "../assets/images/space/epiphanyblue/scrollimages/3.jpg";
import scroll4 from "../assets/images/space/epiphanyblue/scrollimages/4.jpg";
import scroll5 from "../assets/images/space/epiphanyblue/scrollimages/5.jpg";
import scroll6 from "../assets/images/space/epiphanyblue/scrollimages/6.jpg";
import scroll7 from "../assets/images/space/epiphanyblue/scrollimages/7.jpg";
import scroll8 from "../assets/images/space/epiphanyblue/scrollimages/8.jpg";
import scroll9 from "../assets/images/space/epiphanyblue/scrollimages/9.jpg";
import scroll10 from "../assets/images/space/epiphanyblue/scrollimages/10.jpg";
import scroll211 from "../assets/images/space/epiphanyblue/scrollimages/11.jpg";
import scroll212 from "../assets/images/space/epiphanyblue/scrollimages/12.jpg";
import scroll11 from "../assets/images/space/chalet/scrollimages/1.jpg";
import scroll12 from "../assets/images/space/chalet/scrollimages/2.jpg";
import scroll13 from "../assets/images/space/chalet/scrollimages/3.jpg";
import scroll14 from "../assets/images/space/chalet/scrollimages/4.jpg";
import scroll15 from "../assets/images/space/chalet/scrollimages/5.jpg";
import scroll16 from "../assets/images/space/chalet/scrollimages/6.jpg";
import scroll17 from "../assets/images/space/chalet/scrollimages/7.jpg";
import scroll18 from "../assets/images/space/chalet/scrollimages/8.jpg";
import scroll19 from "../assets/images/space/chalet/scrollimages/9.jpg";
import scroll20 from "../assets/images/space/chalet/scrollimages/10.jpg";
import scroll21 from "../assets/images/space/BespokePenthouse/scrollimages/1.jpg";
import scroll22 from "../assets/images/space/BespokePenthouse/scrollimages/2.jpg";
import scroll23 from "../assets/images/space/BespokePenthouse/scrollimages/3.jpg";
import scroll24 from "../assets/images/space/BespokePenthouse/scrollimages/4.jpg";
import scroll25 from "../assets/images/space/BespokePenthouse/scrollimages/5.jpg";
import scroll26 from "../assets/images/space/BespokePenthouse/scrollimages/6.jpg";
import scroll27 from "../assets/images/space/BespokePenthouse/scrollimages/7.jpg";
import scroll28 from "../assets/images/space/BespokePenthouse/scrollimages/8.jpg";
import scroll29 from "../assets/images/space/BespokePenthouse/scrollimages/9.jpg";
import scroll30 from "../assets/images/space/BespokePenthouse/scrollimages/10.jpg";
import scroll31 from "../assets/images/space/greenpark/scrollimages/1.jpg";
import scroll32 from "../assets/images/space/greenpark/scrollimages/2.jpg";
import scroll33 from "../assets/images/space/greenpark/scrollimages/3.jpg";
import scroll34 from "../assets/images/space/greenpark/scrollimages/4.jpg";
import scroll35 from "../assets/images/space/greenpark/scrollimages/5.jpg";
import scroll36 from "../assets/images/space/greenpark/scrollimages/6.jpg";
import scroll37 from "../assets/images/space/greenpark/scrollimages/7.jpg";
import scroll38 from "../assets/images/space/greenpark/scrollimages/8.jpg";
import scroll39 from "../assets/images/space/greenpark/scrollimages/9.jpg";
import scroll40 from "../assets/images/space/greenpark/scrollimages/10.jpg";
import scroll41 from "../assets/images/space/dubaivilla/scrollimages//1.jpg";
import scroll42 from "../assets/images/space/dubaivilla/scrollimages//2.jpg";
import scroll43 from "../assets/images/space/dubaivilla/scrollimages//3.jpg";
import scroll44 from "../assets/images/space/dubaivilla/scrollimages//4.jpg";
import scroll45 from "../assets/images/space/dubaivilla/scrollimages//5.jpg";
import scroll46 from "../assets/images/space/dubaivilla/scrollimages//6.jpg";
import scroll47 from "../assets/images/space/dubaivilla/scrollimages//7.jpg";
import scroll48 from "../assets/images/space/dubaivilla/scrollimages//8.jpg";
import scroll49 from "../assets/images/space/dubaivilla/scrollimages//9.jpg";
import scroll50 from "../assets/images/space/dubaivilla/scrollimages//10.jpg";
import scroll201 from "../assets/images/space/dubaivilla/scrollimages//11.jpg";
import scroll202 from "../assets/images/space/dubaivilla/scrollimages//12.jpg";
import scroll203 from "../assets/images/space/dubaivilla/scrollimages//13.jpg";
import scroll204 from "../assets/images/space/dubaivilla/scrollimages//14.jpg";
import scroll205 from "../assets/images/space/dubaivilla/scrollimages//15.jpg";
import scroll206 from "../assets/images/space/dubaivilla/scrollimages//16.jpg";
import scroll207 from "../assets/images/space/dubaivilla/scrollimages//17.jpg";
import scroll51 from "../assets/images/space/eclecticapartment/scrollimages/1.jpg";
import scroll52 from "../assets/images/space/eclecticapartment/scrollimages/2.jpg";
import scroll53 from "../assets/images/space/eclecticapartment/scrollimages/3.jpg";
import scroll54 from "../assets/images/space/eclecticapartment/scrollimages/4.jpg";
import scroll55 from "../assets/images/space/eclecticapartment/scrollimages/5.jpg";
import scroll56 from "../assets/images/space/eclecticapartment/scrollimages/6.jpg";
import scroll57 from "../assets/images/space/eclecticapartment/scrollimages/7.jpg";
import scroll58 from "../assets/images/space/eclecticapartment/scrollimages/8.jpg";
import scroll59 from "../assets/images/space/eclecticapartment/scrollimages/9.jpg";
import scroll60 from "../assets/images/space/eclecticapartment/scrollimages/10.jpg";
import scroll208 from "../assets/images/space/eclecticapartment/scrollimages/11.jpg";
import scroll209 from "../assets/images/space/eclecticapartment/scrollimages/12.jpg";
import scroll210 from "../assets/images/space/eclecticapartment/scrollimages/13.jpg";
import scroll61 from "../assets/images/space/luxuryCorporate/scrollimages/1.jpg";
import scroll62 from "../assets/images/space/luxuryCorporate/scrollimages/2.jpg";
import scroll63 from "../assets/images/space/luxuryCorporate/scrollimages/3.jpg";
import scroll64 from "../assets/images/space/luxuryCorporate/scrollimages/4.jpg";
import scroll65 from "../assets/images/space/luxuryCorporate/scrollimages/5.jpg";
import scroll66 from "../assets/images/space/luxuryCorporate/scrollimages/6.jpg";
import scroll67 from "../assets/images/space/luxuryCorporate/scrollimages/7.jpg";
import scroll68 from "../assets/images/space/luxuryCorporate/scrollimages/8.jpg";
import scroll69 from "../assets/images/space/luxuryCorporate/scrollimages/9.jpg";
import scroll70 from "../assets/images/space/luxuryCorporate/scrollimages/10.jpg";
import scroll71 from "../assets/images/space/luxurypenthouse/scrollimages/1.jpg";
import scroll72 from "../assets/images/space/luxurypenthouse/scrollimages/2.jpg";
import scroll73 from "../assets/images/space/luxurypenthouse/scrollimages/3.jpg";
import scroll74 from "../assets/images/space/luxurypenthouse/scrollimages/4.jpg";
import scroll75 from "../assets/images/space/luxurypenthouse/scrollimages/5.jpg";
import scroll76 from "../assets/images/space/luxurypenthouse/scrollimages/6.jpg";
import scroll77 from "../assets/images/space/luxurypenthouse/scrollimages/7.jpg";
import scroll78 from "../assets/images/space/luxurypenthouse/scrollimages/8.jpg";
import scroll79 from "../assets/images/space/luxurypenthouse/scrollimages/9.jpg";
import scroll80 from "../assets/images/space/luxurypenthouse/scrollimages/10.jpg";
import scroll233 from "../assets/images/space/luxurypenthouse/scrollimages/11.jpg";
import scroll81 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/1.jpg";
import scroll82 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/2.jpg";
import scroll83 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/3.jpg";
import scroll84 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/4.jpg";
import scroll85 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/5.jpg";
import scroll86 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/6.jpg";
import scroll87 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/7.jpg";
import scroll88 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/8.jpg";
import scroll89 from "../assets/images/space/LuxuryOfficeBKCMumbai/scrollimages/9.jpg";
import scroll91 from "../assets/images/space/goldgym14/scrollimages/1.jpg";
import scroll92 from "../assets/images/space/goldgym14/scrollimages/2.jpg";
import scroll93 from "../assets/images/space/goldgym14/scrollimages/3.jpg";
import scroll94 from "../assets/images/space/goldgym14/scrollimages/4.jpg";
import scroll95 from "../assets/images/space/goldgym14/scrollimages/5.jpg";
import scroll96 from "../assets/images/space/goldgym14/scrollimages/6.jpg";
import scroll97 from "../assets/images/space/goldgym14/scrollimages/7.jpg";
import scroll98 from "../assets/images/space/goldgym14/scrollimages/8.jpg";
import scroll99 from "../assets/images/space/goldgym14/scrollimages/9.jpg";
import scroll100 from "../assets/images/space/goldgym14/scrollimages/10.jpg";
import scroll213 from "../assets/images/space/goldgym14/scrollimages/11.jpg";
import scroll214 from "../assets/images/space/goldgym14/scrollimages/12.jpg";
import scroll215 from "../assets/images/space/goldgym14/scrollimages/13.jpg";
import scroll216 from "../assets/images/space/goldgym14/scrollimages/14.jpg";
import scroll217 from "../assets/images/space/goldgym14/scrollimages/15.jpg";
import scroll218 from "../assets/images/space/goldgym14/scrollimages/16.jpg";
import scroll219 from "../assets/images/space/goldgym14/scrollimages/17.jpg";
import scroll220 from "../assets/images/space/goldgym14/scrollimages/18.jpg";
import scroll221 from "../assets/images/space/goldgym14/scrollimages/19.jpg";
import scroll101 from "../assets/images/space/plushoffice/scrollimages//1.jpg";
import scroll102 from "../assets/images/space/plushoffice/scrollimages//2.jpg";
import scroll103 from "../assets/images/space/plushoffice/scrollimages//3.jpg";
import scroll104 from "../assets/images/space/plushoffice/scrollimages//4.jpg";
import scroll105 from "../assets/images/space/plushoffice/scrollimages//5.jpg";
import scroll106 from "../assets/images/space/plushoffice/scrollimages//6.jpg";
import scroll107 from "../assets/images/space/plushoffice/scrollimages//7.jpg";
import scroll108 from "../assets/images/space/plushoffice/scrollimages//8.jpg";
import scroll109 from "../assets/images/space/plushoffice/scrollimages//9.jpg";
import scroll110 from "../assets/images/space/plushoffice/scrollimages//10.jpg";
import scroll234 from "../assets/images/space/plushoffice/scrollimages//11.jpg";
import scroll111 from "../assets/images/space/christmasstyling/scrollimages/1.jpg";
import scroll112 from "../assets/images/space/christmasstyling/scrollimages/2.jpg";
import scroll113 from "../assets/images/space/christmasstyling/scrollimages/3.jpg";
import scroll114 from "../assets/images/space/christmasstyling/scrollimages/4.jpg";
import scroll115 from "../assets/images/space/christmasstyling/scrollimages/5.jpg";
import scroll116 from "../assets/images/space/christmasstyling/scrollimages/6.jpg";
import scroll117 from "../assets/images/space/christmasstyling/scrollimages/7.jpg";
import scroll118 from "../assets/images/space/christmasstyling/scrollimages/8.jpg";
import scroll119 from "../assets/images/space/christmasstyling/scrollimages/9.jpg";
import scroll120 from "../assets/images/space/christmasstyling/scrollimages/10.jpg";
import scroll121 from "../assets/images/space/designstudio/scrollimages/1.jpg";
import scroll122 from "../assets/images/space/designstudio/scrollimages/2.jpg";
import scroll123 from "../assets/images/space/designstudio/scrollimages/3.jpg";
import scroll124 from "../assets/images/space/designstudio/scrollimages/4.jpg";
import scroll125 from "../assets/images/space/designstudio/scrollimages/5.jpg";
import scroll126 from "../assets/images/space/designstudio/scrollimages/6.jpg";
import scroll127 from "../assets/images/space/designstudio/scrollimages/7.jpg";
import scroll128 from "../assets/images/space/designstudio/scrollimages/8.jpg";
import scroll129 from "../assets/images/space/designstudio/scrollimages/9.jpg";
import scroll130 from "../assets/images/space/designstudio/scrollimages/10.jpg";
import scroll131 from "../assets/images/space/corporateOffice/scrollimages/1.jpg";
import scroll132 from "../assets/images/space/corporateOffice/scrollimages/2.jpg";
import scroll133 from "../assets/images/space/corporateOffice/scrollimages/3.jpg";
import scroll134 from "../assets/images/space/corporateOffice/scrollimages/4.jpg";
import scroll135 from "../assets/images/space/corporateOffice/scrollimages/5.jpg";
import scroll136 from "../assets/images/space/corporateOffice/scrollimages/6.jpg";
import scroll137 from "../assets/images/space/corporateOffice/scrollimages/7.jpg";
import scroll138 from "../assets/images/space/corporateOffice/scrollimages/8.jpg";
import scroll139 from "../assets/images/space/corporateOffice/scrollimages/9.jpg";
import scroll141 from "../assets/images/space/weekendhouse/scrollimages/1.jpg";
import scroll142 from "../assets/images/space/weekendhouse/scrollimages/2.jpg";
import scroll143 from "../assets/images/space/weekendhouse/scrollimages/3.jpg";
import scroll144 from "../assets/images/space/weekendhouse/scrollimages/4.jpg";
import scroll145 from "../assets/images/space/weekendhouse/scrollimages/5.jpg";
import scroll146 from "../assets/images/space/weekendhouse/scrollimages/6.jpg";
import scroll147 from "../assets/images/space/weekendhouse/scrollimages/7.jpg";
import scroll148 from "../assets/images/space/weekendhouse/scrollimages/8.jpg";
import scroll149 from "../assets/images/space/weekendhouse/scrollimages/9.jpg";
import scroll150 from "../assets/images/space/weekendhouse/scrollimages/10.jpg";
import scroll237 from "../assets/images/space/weekendhouse/scrollimages/11.jpg";
import scroll238 from "../assets/images/space/weekendhouse/scrollimages/12.jpg";
import scroll239 from "../assets/images/space/weekendhouse/scrollimages/13.jpg";
import scroll240 from "../assets/images/space/weekendhouse/scrollimages/14.jpg";
import scroll241 from "../assets/images/space/weekendhouse/scrollimages/15.jpg";
import scroll151 from "../assets/images/space/goldgym43/scrollimages/1.jpg";
import scroll152 from "../assets/images/space/goldgym43/scrollimages/2.jpg";
import scroll153 from "../assets/images/space/goldgym43/scrollimages/3.jpg";
import scroll154 from "../assets/images/space/goldgym43/scrollimages/4.jpg";
import scroll155 from "../assets/images/space/goldgym43/scrollimages/5.jpg";
import scroll156 from "../assets/images/space/goldgym43/scrollimages/6.jpg";
import scroll157 from "../assets/images/space/goldgym43/scrollimages/7.jpg";
import scroll158 from "../assets/images/space/goldgym43/scrollimages/8.jpg";
import scroll159 from "../assets/images/space/goldgym43/scrollimages/9.jpg";
import scroll160 from "../assets/images/space/goldgym43/scrollimages/10.jpg";
import scroll222 from "../assets/images/space/goldgym43/scrollimages/11.jpg";
import scroll161 from "../assets/images/space/Housewarming/scrollimages/1.jpg";
import scroll162 from "../assets/images/space/Housewarming/scrollimages/2.jpg";
import scroll163 from "../assets/images/space/Housewarming/scrollimages/3.jpg";
import scroll164 from "../assets/images/space/Housewarming/scrollimages/4.jpg";
import scroll165 from "../assets/images/space/Housewarming/scrollimages/5.jpg";
import scroll166 from "../assets/images/space/Housewarming/scrollimages/6.jpg";
import scroll167 from "../assets/images/space/Housewarming/scrollimages/7.jpg";
import scroll168 from "../assets/images/space/Housewarming/scrollimages/8.jpg";
import scroll169 from "../assets/images/space/Housewarming/scrollimages/9.jpg";
import scroll170 from "../assets/images/space/Housewarming/scrollimages/10.jpg";
import scroll223 from "../assets/images/space/Housewarming/scrollimages/11.jpg";
import scroll224 from "../assets/images/space/Housewarming/scrollimages/12.jpg";
import scroll225 from "../assets/images/space/Housewarming/scrollimages/13.jpg";
import scroll226 from "../assets/images/space/Housewarming/scrollimages/14.jpg";
import scroll227 from "../assets/images/space/Housewarming/scrollimages/15.jpg";
import scroll228 from "../assets/images/space/Housewarming/scrollimages/16.jpg";
import scroll171 from "../assets/images/space/luxuryofficeBKC/scrollimages/1.jpg";
import scroll172 from "../assets/images/space/luxuryofficeBKC/scrollimages/2.jpg";
import scroll173 from "../assets/images/space/luxuryofficeBKC/scrollimages/3.jpg";
import scroll174 from "../assets/images/space/luxuryofficeBKC/scrollimages/4.jpg";
import scroll175 from "../assets/images/space/luxuryofficeBKC/scrollimages/5.jpg";
import scroll176 from "../assets/images/space/luxuryofficeBKC/scrollimages/6.jpg";
import scroll177 from "../assets/images/space/luxuryofficeBKC/scrollimages/7.jpg";
import scroll178 from "../assets/images/space/luxuryofficeBKC/scrollimages/8.jpg";
import scroll179 from "../assets/images/space/luxuryofficeBKC/scrollimages/9.jpg";
import scroll180 from "../assets/images/space/luxuryofficeBKC/scrollimages/10.jpg";
import scroll181 from "../assets/images/space/IndiaDesignID/scrollimages/1.jpg";
import scroll182 from "../assets/images/space/IndiaDesignID/scrollimages/2.jpg";
import scroll183 from "../assets/images/space/IndiaDesignID/scrollimages/3.jpg";
import scroll184 from "../assets/images/space/IndiaDesignID/scrollimages/4.jpg";
import scroll185 from "../assets/images/space/IndiaDesignID/scrollimages/5.jpg";
import scroll186 from "../assets/images/space/IndiaDesignID/scrollimages/6.jpg";
import scroll187 from "../assets/images/space/IndiaDesignID/scrollimages/7.jpg";
import scroll188 from "../assets/images/space/IndiaDesignID/scrollimages/8.jpg";
import scroll189 from "../assets/images/space/IndiaDesignID/scrollimages/9.jpg";
import scroll190 from "../assets/images/space/IndiaDesignID/scrollimages/10.jpg";
import scroll229 from "../assets/images/space/IndiaDesignID/scrollimages/11.jpg";
import scroll230 from "../assets/images/space/IndiaDesignID/scrollimages/12.jpg";
import scroll231 from "../assets/images/space/IndiaDesignID/scrollimages/13.jpg";
import scroll232 from "../assets/images/space/IndiaDesignID/scrollimages/14.jpg";
import scroll191 from "../assets/images/space/restrobar/scrollimages/1.jpg";
import scroll192 from "../assets/images/space/restrobar/scrollimages/2.jpg";
import scroll193 from "../assets/images/space/restrobar/scrollimages/3.jpg";
import scroll194 from "../assets/images/space/restrobar/scrollimages/4.jpg";
import scroll195 from "../assets/images/space/restrobar/scrollimages/5.jpg";
import scroll196 from "../assets/images/space/restrobar/scrollimages/6.jpg";
import scroll197 from "../assets/images/space/restrobar/scrollimages/7.jpg";
import scroll198 from "../assets/images/space/restrobar/scrollimages/8.jpg";
import scroll199 from "../assets/images/space/restrobar/scrollimages/9.jpg";
import scroll200 from "../assets/images/space/restrobar/scrollimages/10.jpg";
import scroll235 from "../assets/images/space/restrobar/scrollimages/11.jpg";
import scroll236 from "../assets/images/space/restrobar/scrollimages/12.jpg";

//scroll images//

//featuredlogos//

import flogo1 from "../assets/images/space/1.png";
import flogo2 from "../assets/images/space/2.png";
import flogo3 from "../assets/images/space/3.png";
import flogo4 from "../assets/images/space/4.png";
import flogo5 from "../assets/images/space/5.png";
import flogo6 from "../assets/images/space/6.png";
import flogo7 from "../assets/images/space/7.png";
import flogo8 from "../assets/images/space/8.png";
import flogo9 from "../assets/images/space/9.png";
import flogo10 from "../assets/images/space/10.png";
import flogo11 from "../assets/images/space/11.png";
import flogo12 from "../assets/images/space/12.png";
import flogo13 from "../assets/images/space/13.png";
import flogo14 from "../assets/images/space/14.png";
import flogo15 from "../assets/images/space/15.png";
import flogo16 from "../assets/images/space/16.png";
import flogo17 from "../assets/images/space/17.png";
import flogo18 from "../assets/images/space/18.png";
import flogo19 from "../assets/images/space/19.png";

//featuredlogos//

//Scroll Press images//

import slide1 from "../assets/images/press/1/slide1.png";
import slide2 from "../assets/images/press/1/slide2.png";
import slide3 from "../assets/images/press/1/slide3.png";
import slide4 from "../assets/images/press/1/slide4.png";
import slide82 from "../assets/images/press/2/slide1.png";
import slide83 from "../assets/images/press/2/slide2.png";
import slide84 from "../assets/images/press/2/slide3.png";
import slide85 from "../assets/images/press/2/slide4.png";
import slide64 from "../assets/images/press/21/slide1.png";
import slide12 from "../assets/images/press/4/slide1.png";
import slide13 from "../assets/images/press/4/slide2.png";
import slide14 from "../assets/images/press/4/slide3.png";
import slide15 from "../assets/images/press/4/slide4.png";
import slide16 from "../assets/images/press/4/slide5.png";
import slide17 from "../assets/images/press/4/slide6.png";
import slide18 from "../assets/images/press/4/slide7.png";
import slide19 from "../assets/images/press/4/slide8.png";
import slide20 from "../assets/images/press/5/slide1.png";
import slide21 from "../assets/images/press/5/slide2.png";
import slide22 from "../assets/images/press/5/slide3.png";
import slide23 from "../assets/images/press/5/slide4.png";
import slide24 from "../assets/images/press/5/slide5.png";
import slide28 from "../assets/images/press/6/slide1.png";
import slide29 from "../assets/images/press/6/slide2.png";
import slide35 from "../assets/images/press/7/slide1.png";
import slide36 from "../assets/images/press/7/slide2.png";
import slide37 from "../assets/images/press/7/slide3.png";
import slide38 from "../assets/images/press/7/slide4.png";
import slide39 from "../assets/images/press/7/slide5.png";
import slide40 from "../assets/images/press/7/slide6.png";
import slide52 from "../assets/images/press/8/slide1.png";
import slide59 from "../assets/images/press/9/slide1.png";
import slide60 from "../assets/images/press/9/slide2.png";
import slide61 from "../assets/images/press/9/slide3.png";
import slide53 from "../assets/images/press/11/slide1.png";
import slide54 from "../assets/images/press/11/slide2.png";
import slide55 from "../assets/images/press/10/slide1.png";
import slide56 from "../assets/images/press/10/slide2.png";
import slide57 from "../assets/images/press/10/slide3.png";
import slide58 from "../assets/images/press/10/slide4.png";
import slide69 from "../assets/images/press/12/slide1.png";
import slide70 from "../assets/images/press/12/slide2.png";
import slide71 from "../assets/images/press/12/slide3.png";
import slide32 from "../assets/images/press/13/slide1.png";
import slide33 from "../assets/images/press/13/slide2.png";
import slide78 from "../assets/images/press/14/slide1.png";
import slide79 from "../assets/images/press/14/slide2.png";
import slide25 from "../assets/images/press/16/slide1.png";
import slide26 from "../assets/images/press/16/slide2.png";
import slide27 from "../assets/images/press/16/slide3.png";
import slide42 from "../assets/images/press/17/slide1.png";
import slide43 from "../assets/images/press/17/slide2.png";
import slide44 from "../assets/images/press/17/slide3.png";
import slide45 from "../assets/images/press/17/slide4.png";
import slide94 from "../assets/images/press/15/slide1.png";
import slide95 from "../assets/images/press/15/slide2.png";
import slide96 from "../assets/images/press/15/slide3.png";
import slide97 from "../assets/images/press/15/slide4.png";
import slide98 from "../assets/images/press/15/slide5.png";
import slide99 from "../assets/images/press/15/slide6.png";
import slide63 from "../assets/images/press/18/slide1.png";
import slide72 from "../assets/images/press/19/slide1.png";
import slide46 from "../assets/images/press/20/slide1.png";
import slide47 from "../assets/images/press/20/slide2.png";
import slide87 from "../assets/images/press/21/slide1.png";
import slide88 from "../assets/images/press/21/slide2.png";
import slide80 from "../assets/images/press/22/slide1.png";
import slide81 from "../assets/images/press/22/slide2.png";
import slide41 from "../assets/images/press/23/slide1.png";
import slide100 from "../assets/images/press/24/slide1.png";
import slide101 from "../assets/images/press/24/slide2.png";
import slide48 from "../assets/images/press/25/slide1.png";
import slide49 from "../assets/images/press/25/slide2.png";
import slide50 from "../assets/images/press/25/slide3.png";
import slide51 from "../assets/images/press/25/slide4.png";
import slide86 from "../assets/images/press/26/slide1.png";
import slide34 from "../assets/images/press/27/slide1.png";
import slide67 from "../assets/images/press/28/slide1.png";
import slide68 from "../assets/images/press/28/slide2.png";
import slide74 from "../assets/images/press/29/slide1.png";
import slide75 from "../assets/images/press/29/slide2.png";
import slide76 from "../assets/images/press/29/slide3.png";
import slide77 from "../assets/images/press/29/slide4.png";
import slide73 from "../assets/images/press/30/slide1.png";
import slide89 from "../assets/images/press/31/slide1.png";
import slide90 from "../assets/images/press/31/slide2.png";
import slide91 from "../assets/images/press/31/slide3.png";
import slide92 from "../assets/images/press/31/slide4.png";
import slide66 from "../assets/images/press/32/slide1.png";
import slide65 from "../assets/images/press/33/slide1.png";
import slide30 from "../assets/images/press/34/slide1.png";
import slide31 from "../assets/images/press/34/slide2.png";
import slide93 from "../assets/images/press/35/slide1.png";

//Scroll Press images//

//Object page images//

import objectScroll1 from "../assets/images/objects/object1/Lock&key1.mov";
import objectScroll2 from "../assets/images/objects/object1/Lock&key2.jpg";
import objectScroll3 from "../assets/images/objects/object1/Lock&key3.jpg";
import objectScroll4 from "../assets/images/objects/object1/Lock&key4.jpg";
import objectScroll5 from "../assets/images/objects/object2/Bench1.mov";
import objectScroll6 from "../assets/images/objects/object2/Bench2.jpg";
import objectScroll7 from "../assets/images/objects/object2/Bench3.jpg";
import objectScroll8 from "../assets/images/objects/object2/Bench4.jpg";
import objectScroll9 from "../assets/images/objects/object2/Bench5.jpg";
import objectScroll10 from "../assets/images/objects/object3/KING1.jpg";
import objectScroll11 from "../assets/images/objects/object3/KING2.jpg";
import objectScroll12 from "../assets/images/objects/object3/KING3.jpg";
import objectScroll13 from "../assets/images/objects/object3/KING4.jpg";
import objectScroll14 from "../assets/images/objects/object5/Parcel1.jpg";
import objectScroll15 from "../assets/images/objects/object5/Parcel2.jpg";
import objectScroll16 from "../assets/images/objects/object5/Parcel3.jpg";
import objectScroll17 from "../assets/images/objects/object5/Parcel4.jpg";
import objectScroll18 from "../assets/images/objects/object5/Parcel5.jpg";
import objectScroll19 from "../assets/images/objects/object5/Parcel6.jpg";
import objectScroll20 from "../assets/images/objects/object6/Reflect1.jpg";
import objectScroll21 from "../assets/images/objects/object6/Reflect2.jpg";
import objectScroll22 from "../assets/images/objects/object6/Reflect3.jpg";
import objectScroll23 from "../assets/images/objects/object6/Reflect4.jpg";
import objectScroll24 from "../assets/images/objects/object7/Thinking1.jpg";
import objectScroll25 from "../assets/images/objects/object7/Thinking2.jpg";
import objectScroll26 from "../assets/images/objects/object7/Thinking3.jpg";
import objectScroll27 from "../assets/images/objects/object7/Thinking4.jpg";
import objectScroll28 from "../assets/images/objects/object7/Thinking5.jpg";

//object page images//

const magzine1 = [
  { id: 1, src: slide1 },
  { id: 2, src: slide2 },
  { id: 3, src: slide3 },
  { id: 3, src: slide4 },
];
const magzine2 = [
  { id: 1, src: slide82 },
  { id: 2, src: slide83 },
  { id: 3, src: slide84 },
  { id: 4, src: slide85 },
];
const magzine3 = [{ id: 1, src: slide64 }];
const magzine4 = [
  { id: 1, src: slide12 },
  { id: 2, src: slide13 },
  { id: 3, src: slide14 },
  { id: 4, src: slide15 },
  { id: 5, src: slide16 },
  { id: 6, src: slide17 },
  { id: 7, src: slide18 },
  { id: 8, src: slide19 },
];
const magzine5 = [
  { id: 1, src: slide20 },
  { id: 2, src: slide21 },
  { id: 3, src: slide22 },
  { id: 4, src: slide23 },
  { id: 5, src: slide24 },
];
const magzine6 = [
  { id: 1, src: slide28 },
  { id: 2, src: slide29 },
];
const magzine7 = [
  { id: 1, src: slide35 },
  { id: 2, src: slide36 },
  { id: 3, src: slide37 },
  { id: 4, src: slide38 },
  { id: 5, src: slide39 },
  { id: 6, src: slide40 },
];
const magzine8 = [{ id: 1, src: slide52 }];
const magzine9 = [
  { id: 1, src: slide59 },
  { id: 2, src: slide60 },
  { id: 3, src: slide61 },
];
const magzine10 = [
  { id: 1, src: slide55 },
  { id: 2, src: slide56 },
  { id: 3, src: slide57 },
  { id: 4, src: slide58 },
];
const magzine11 = [
  { id: 1, src: slide53 },
  { id: 2, src: slide54 },
];
const magzine12 = [
  { id: 1, src: slide69 },
  { id: 2, src: slide70 },
  { id: 3, src: slide71 },
];
const magzine13 = [
  { id: 1, src: slide32 },
  { id: 2, src: slide33 },
];
const magzine14 = [
  { id: 1, src: slide78 },
  { id: 2, src: slide79 },
];
const magzine15 = [
  { id: 1, src: slide94 },
  { id: 2, src: slide95 },
  { id: 3, src: slide96 },
  { id: 4, src: slide97 },
  { id: 5, src: slide98 },
  { id: 6, src: slide99 },
];
const magzine16 = [
  { id: 1, src: slide25 },
  { id: 2, src: slide26 },
  { id: 3, src: slide27 },
];
const magzine17 = [
  { id: 1, src: slide42 },
  { id: 2, src: slide43 },
  { id: 3, src: slide44 },
  { id: 4, src: slide45 },
];
const magzine18 = [{ id: 1, src: slide63 }];
const magzine19 = [{ id: 1, src: slide72 }];
const magzine20 = [
  { id: 1, src: slide46 },
  { id: 2, src: slide47 },
];
const magzine21 = [
  { id: 1, src: slide87 },
  { id: 2, src: slide88 },
];
const magzine22 = [
  { id: 1, src: slide80 },
  { id: 2, src: slide81 },
];
const magzine23 = [{ id: 1, src: slide41 }];
const magzine24 = [
  { id: 1, src: slide100 },
  { id: 2, src: slide101 },
];
const magzine25 = [
  { id: 1, src: slide48 },
  { id: 2, src: slide49 },
  { id: 3, src: slide50 },
  { id: 4, src: slide51 },
];
const magzine26 = [{ id: 1, src: slide86 }];
const magzine27 = [{ id: 1, src: slide34 }];
const magzine28 = [
  { id: 1, src: slide67 },
  { id: 2, src: slide68 },
];
const magzine29 = [
  { id: 1, src: slide74 },
  { id: 2, src: slide75 },
  { id: 3, src: slide76 },
  { id: 4, src: slide77 },
];
const magzine30 = [{ id: 1, src: slide73 }];
const magzine31 = [
  { id: 1, src: slide89 },
  { id: 2, src: slide90 },
  { id: 3, src: slide91 },
  { id: 4, src: slide92 },
];
const magzine32 = [{ id: 1, src: slide66 }];
const magzine33 = [{ id: 1, src: slide65 }];
const magzine34 = [
  { id: 1, src: slide30 },
  { id: 2, src: slide31 },
];
const magzine35 = [{ id: 1, src: slide93 }];

// dynamic images object//

const lockkey = [
  { id: 1, src: objectScroll1, type: 2 },
  { id: 2, src: objectScroll2, type: 1 },
  { id: 3, src: objectScroll3, type: 1 },
  { id: 4, src: objectScroll4, type: 1 },
];
const bench = [
  { id: 1, src: objectScroll5, type: 2 },
  { id: 2, src: objectScroll6, type: 1 },
  { id: 3, src: objectScroll7, type: 1 },
  { id: 4, src: objectScroll8, type: 1 },
  { id: 4, src: objectScroll9, type: 1 },
];
const king = [
  { id: 1, src: objectScroll10, type: 1 },
  { id: 2, src: objectScroll11, type: 1 },
  { id: 3, src: objectScroll12, type: 1 },
  { id: 4, src: objectScroll13, type: 1 },
];
const parcel = [
  { id: 1, src: objectScroll14, type: 1 },
  { id: 2, src: objectScroll15, type: 1 },
  { id: 3, src: objectScroll16, type: 1 },
  { id: 4, src: objectScroll17, type: 1 },
  { id: 3, src: objectScroll18, type: 1 },
  { id: 4, src: objectScroll19, type: 1 },
];
const timeReflect = [
  { id: 1, src: objectScroll20, type: 1 },
  { id: 2, src: objectScroll21, type: 1 },
  { id: 3, src: objectScroll22, type: 1 },
  { id: 4, src: objectScroll23, type: 1 },
];
const faces = [
  { id: 1, src: objectScroll24, type: 1 },
  { id: 2, src: objectScroll25, type: 1 },
  { id: 3, src: objectScroll26, type: 1 },
  { id: 4, src: objectScroll27, type: 1 },
  { id: 3, src: objectScroll28, type: 1 },
];
//dynamic images object//

const epiphanyblue = [
  { id: 1, src: scroll1 },
  { id: 2, src: scroll2 },
  { id: 3, src: scroll3 },
  { id: 4, src: scroll4 },
  { id: 5, src: scroll5 },
  { id: 6, src: scroll6 },
  { id: 7, src: scroll7 },
  { id: 8, src: scroll8 },
  { id: 9, src: scroll9 },
  { id: 10, src: scroll10 },
  { id: 11, src: scroll211 },
  { id: 12, src: scroll212 },
];
const chalet = [
  { id: 1, src: scroll11 },
  { id: 2, src: scroll12 },
  { id: 3, src: scroll13 },
  { id: 4, src: scroll14 },
  { id: 5, src: scroll15 },
  { id: 6, src: scroll16 },
  { id: 7, src: scroll17 },
  { id: 8, src: scroll18 },
  { id: 9, src: scroll19 },
  { id: 10, src: scroll20 },
];
const bespokePenthouse = [
  { id: 1, src: scroll21 },
  { id: 2, src: scroll22 },
  { id: 3, src: scroll23 },
  { id: 4, src: scroll24 },
  { id: 5, src: scroll25 },
  { id: 6, src: scroll26 },
  { id: 7, src: scroll27 },
  { id: 8, src: scroll28 },
  { id: 9, src: scroll29 },
  { id: 10, src: scroll30 },
];
const greenPark = [
  { id: 1, src: scroll31 },
  { id: 2, src: scroll32 },
  { id: 3, src: scroll33 },
  { id: 4, src: scroll34 },
  { id: 5, src: scroll35 },
  { id: 6, src: scroll36 },
  { id: 7, src: scroll37 },
  { id: 8, src: scroll38 },
  { id: 9, src: scroll39 },
  { id: 10, src: scroll40 },
];
const villa = [
  { id: 1, src: scroll41 },
  { id: 2, src: scroll42 },
  { id: 3, src: scroll43 },
  { id: 4, src: scroll44 },
  { id: 5, src: scroll45 },
  { id: 6, src: scroll46 },
  { id: 7, src: scroll47 },
  { id: 8, src: scroll48 },
  { id: 9, src: scroll49 },
  { id: 10, src: scroll50 },
  { id: 11, src: scroll201 },
  { id: 12, src: scroll202 },
  { id: 13, src: scroll203 },
  { id: 14, src: scroll204 },
  { id: 15, src: scroll205 },
  { id: 16, src: scroll206 },
  { id: 17, src: scroll207 },
];
const eclecticApartment = [
  { id: 1, src: scroll51 },
  { id: 2, src: scroll52 },
  { id: 3, src: scroll53 },
  { id: 4, src: scroll54 },
  { id: 5, src: scroll55 },
  { id: 6, src: scroll56 },
  { id: 7, src: scroll57 },
  { id: 8, src: scroll58 },
  { id: 9, src: scroll59 },
  { id: 10, src: scroll60 },
  { id: 11, src: scroll208 },
  { id: 12, src: scroll209 },
  { id: 13, src: scroll210 },
];
const luxuryCorporate = [
  { id: 1, src: scroll61 },
  { id: 2, src: scroll62 },
  { id: 3, src: scroll63 },
  { id: 4, src: scroll64 },
  { id: 5, src: scroll65 },
  { id: 6, src: scroll66 },
  { id: 7, src: scroll67 },
  { id: 8, src: scroll68 },
  { id: 9, src: scroll69 },
  { id: 10, src: scroll70 },
];
const luxuryPenthouse = [
  { id: 1, src: scroll71 },
  { id: 2, src: scroll72 },
  { id: 3, src: scroll73 },
  { id: 4, src: scroll74 },
  { id: 5, src: scroll75 },
  { id: 6, src: scroll76 },
  { id: 7, src: scroll77 },
  { id: 8, src: scroll78 },
  { id: 9, src: scroll79 },
  { id: 10, src: scroll80 },
  { id: 11, src: scroll233 },
];
const luxuryOfficeBKCMumbai = [
  { id: 1, src: scroll81 },
  { id: 2, src: scroll82 },
  { id: 3, src: scroll83 },
  { id: 4, src: scroll84 },
  { id: 5, src: scroll85 },
  { id: 6, src: scroll86 },
  { id: 7, src: scroll87 },
  { id: 8, src: scroll88 },
  { id: 9, src: scroll89 },
];
const goldGym14 = [
  { id: 1, src: scroll91 },
  { id: 2, src: scroll92 },
  { id: 3, src: scroll93 },
  { id: 4, src: scroll94 },
  { id: 5, src: scroll95 },
  { id: 6, src: scroll96 },
  { id: 7, src: scroll97 },
  { id: 8, src: scroll98 },
  { id: 9, src: scroll99 },
  { id: 10, src: scroll100 },
  { id: 11, src: scroll213 },
  { id: 12, src: scroll214 },
  { id: 13, src: scroll215 },
  { id: 14, src: scroll216 },
  { id: 15, src: scroll217 },
  { id: 16, src: scroll218 },
  { id: 17, src: scroll219 },
  { id: 18, src: scroll220 },
  { id: 19, src: scroll221 },
];
const plush = [
  { id: 1, src: scroll101 },
  { id: 2, src: scroll102 },
  { id: 3, src: scroll103 },
  { id: 4, src: scroll104 },
  { id: 5, src: scroll105 },
  { id: 6, src: scroll106 },
  { id: 7, src: scroll107 },
  { id: 8, src: scroll108 },
  { id: 9, src: scroll109 },
  { id: 10, src: scroll110 },
  { id: 11, src: scroll234 },
];
const christmasStyling = [
  { id: 1, src: scroll111 },
  { id: 2, src: scroll112 },
  { id: 3, src: scroll113 },
  { id: 4, src: scroll114 },
  { id: 5, src: scroll115 },
  { id: 6, src: scroll116 },
  { id: 7, src: scroll117 },
  { id: 8, src: scroll118 },
  { id: 9, src: scroll119 },
  { id: 10, src: scroll120 },
];
const designStudio = [
  { id: 1, src: scroll121 },
  { id: 2, src: scroll122 },
  { id: 3, src: scroll123 },
  { id: 4, src: scroll124 },
  { id: 5, src: scroll125 },
  { id: 6, src: scroll126 },
  { id: 7, src: scroll127 },
  { id: 8, src: scroll128 },
  { id: 9, src: scroll129 },
  { id: 10, src: scroll130 },
];
const corporateOffice = [
  { id: 1, src: scroll131 },
  { id: 2, src: scroll132 },
  { id: 3, src: scroll133 },
  { id: 4, src: scroll134 },
  { id: 5, src: scroll135 },
  { id: 6, src: scroll136 },
  { id: 7, src: scroll137 },
  { id: 8, src: scroll138 },
  { id: 9, src: scroll139 },
];
const WeekendHome = [
  { id: 1, src: scroll141 },
  { id: 2, src: scroll142 },
  { id: 3, src: scroll143 },
  { id: 4, src: scroll144 },
  { id: 5, src: scroll145 },
  { id: 6, src: scroll146 },
  { id: 7, src: scroll147 },
  { id: 8, src: scroll148 },
  { id: 9, src: scroll149 },
  { id: 10, src: scroll150 },
  { id: 11, src: scroll237 },
  { id: 12, src: scroll238 },
  { id: 13, src: scroll239 },
  { id: 14, src: scroll240 },
  { id: 15, src: scroll241 },
];
const goldGym43 = [
  { id: 1, src: scroll151 },
  { id: 2, src: scroll152 },
  { id: 3, src: scroll153 },
  { id: 4, src: scroll154 },
  { id: 5, src: scroll155 },
  { id: 6, src: scroll156 },
  { id: 7, src: scroll157 },
  { id: 8, src: scroll158 },
  { id: 9, src: scroll159 },
  { id: 10, src: scroll160 },
  { id: 11, src: scroll222 },
];
const housewarming = [
  { id: 1, src: scroll161 },
  { id: 2, src: scroll162 },
  { id: 3, src: scroll163 },
  { id: 4, src: scroll164 },
  { id: 5, src: scroll165 },
  { id: 6, src: scroll166 },
  { id: 7, src: scroll167 },
  { id: 8, src: scroll168 },
  { id: 9, src: scroll169 },
  { id: 10, src: scroll170 },
  { id: 11, src: scroll223 },
  { id: 12, src: scroll224 },
  { id: 13, src: scroll225 },
  { id: 14, src: scroll226 },
  { id: 15, src: scroll227 },
  { id: 16, src: scroll228 },
];
const luxuryofficeBKC = [
  { id: 1, src: scroll171 },
  { id: 2, src: scroll172 },
  { id: 3, src: scroll173 },
  { id: 4, src: scroll174 },
  { id: 5, src: scroll175 },
  { id: 6, src: scroll176 },
  { id: 7, src: scroll177 },
  { id: 8, src: scroll178 },
  { id: 9, src: scroll179 },
  { id: 10, src: scroll180 },
];
const IndiaDesignID = [
  { id: 1, src: scroll181 },
  { id: 2, src: scroll182 },
  { id: 3, src: scroll183 },
  { id: 4, src: scroll184 },
  { id: 5, src: scroll185 },
  { id: 6, src: scroll186 },
  { id: 7, src: scroll187 },
  { id: 8, src: scroll188 },
  { id: 9, src: scroll189 },
  { id: 10, src: scroll190 },
  { id: 11, src: scroll229 },
  { id: 12, src: scroll230 },
  { id: 13, src: scroll231 },
  { id: 14, src: scroll232 },
];
const restroBar = [
  { id: 1, src: scroll191 },
  { id: 2, src: scroll192 },
  { id: 3, src: scroll193 },
  { id: 4, src: scroll194 },
  { id: 5, src: scroll195 },
  { id: 6, src: scroll196 },
  { id: 7, src: scroll197 },
  { id: 8, src: scroll198 },
  { id: 9, src: scroll199 },
  { id: 10, src: scroll200 },
  { id: 11, src: scroll235 },
  { id: 12, src: scroll236 },
];
//FeaturedLogos//

//FeaturedLogos//

export const APP_NAME = "SanjytSyngh";
export const FACEBOOK_URL = "https://www.facebook.com/sanjyt/";
export const INSTAGRAM_URL = "https://www.instagram.com/sanjytsyngh/";
export const VIMEO_URL = "https://vimeo.com/sanjyt";
export const USER_EMAIL = "hello@sanjytsyngh.com";
export const USER_PHONE = "+91 99999 75099";

export const SpaceListContent = [
  {
    id: 1,
    type: 1,
    title: "Epiphany Blue",
    collage: collage1,
    videoUrl: "",
    featuredImage: featuredImage1,
    media: space1,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Westend Greens , New Delhi</p>",
    detail:
      "In collaboration with luxury Indian design brand Scarlet Splendour this whimsical 9000 sq. ft. New Delhi farmhouse is an ode to a fairy tale-like fantasy land. Taking inspiration from the client’s happiest memories and wishes, we dreamt up a magical wonderland whilst keeping four elements in mind: bright pops of colour, timelessness, quirkiness and the owner’s happiest moment to-date, when her now-husband proposed to her at the iconic Tiffany & Co. The narrative was to create a subterranean fantasy world populated by peculiar, anthropomorphic creatures. ",
    featuredLogo: flogo1,
    scrollingImages: epiphanyblue,
  },
  {
    id: 2,
    type: 1,
    title: "URBAN CHALET",
    collage: "",
    videoUrl: mainvideo2,
    featuredImage: "",
    media: space2,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Chattarpur, New Delhi</p>",
    detail:
      "This 2.5-acre farmhouse is set in Delhi’s Urban Sprawl, Chattarpur. With 5000 square feet built up area, the idea was to relish the landscaping and the waterbodies over the weekend. The extremely crisp brief was to convert this pre-built structure into an Urban Swiss Chalet. Basis of the composition takes dark colour, which is a rare in decoration of Indian residences. Even though it was quite a daunting task complementing the dark elements, each corner has been thoughtfully and tastefully curated to bring a sense of new. This space exudes its own stylish and young personality.",
    featuredLogo: flogo2,
    scrollingImages: chalet,
  },
  {
    id: 3,
    type: 1,
    title: "Bespoke Penthouse",
    collage: collage3,
    videoUrl: mainvideo3,
    featuredImage: featuredImage3,
    media: space3,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Pali Hill, Mumbai</p>",
    detail:
      "This Penthouse Suite is located adjacent to a popular seafront on the west coast of Mumbai. The interior of this Suite combines a sensibility for detail and materiality with a modern sense of space and light. Basis of the composition takes dark colour, which is rare in decoration of Indian residences. The tonal colour palette provides a textural response to the metal accents and pieces of furniture create a stylish character. A massive walk-in closet is animated with a Kevin Reilly light fixture.  Each element has taken its place in the space and created a look that is luxurious and sophisticated.",
    featuredLogo: flogo3,
    scrollingImages: bespokePenthouse,
  },
  {
    id: 4,
    type: 1,
    title: "Bachelor Apartment",
    collage: collage4,
    videoUrl: "",
    featuredImage: featuredImage4,
    media: space4,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Green Park, New Delhi</p>",
    detail:
      "This 2400 sq. ft. space belongs to a young professional. With a brief to create a young vibrant space with loads of colour we decided to create a space where art plays a major role. One of the other important factors considered was to keep the personal space away from the formal areas. Located in a quiet residential lane in South Delhi called Green Park, this is a corner plot with a 3 side view and this apartment is on the second floor. There is plenty of natural light that bathes the space.",
    featuredLogo: flogo4,
    scrollingImages: greenPark,
  },
  {
    id: 5,
    type: 1,
    title: "Dubai Holiday Villa ",
    collage: collage5,
    videoUrl: "",
    featuredImage: featuredImage5,
    media: space5,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>District One, Dubai</p>",
    detail:
      "This 12000 square feet holiday home is set in Dubai’s Urban Sprawl, District One. With 9000 square feet built up area, the idea was to create a home away from home. The extremely crisp brief was to create a comfortable space that exudes its own stylish and young personality. Split into two levels the ground floor is treated as a formal space and also for house guests. First floor is completely private for family’s own use whenever in Dubai. Each room has been thoughtfully designed with its own colour palette and tastefully curated to bring a sense of new. The formal living on the ground floor and the family lounge on the first floor have views to the pool and Burj Khalifa.",
    featuredLogo: flogo5,
    scrollingImages: villa,
  },
  {
    id: 6,
    type: 1,
    title: "Eclectic Apartment",
    isVideo: 1,
    collage: collage6,
    videoUrl: "",
    featuredImage: featuredImage6,
    media: space6,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>New Delhi</p>",
    detail:
      "Located in one of the quieter lanes of South Delhi, this house is quite a mix of influences. It’s a story expressed through different mediums. The idea was to create edgy interior corners with interesting pieces of furniture, art and sculptures while everything else becomes incidental. This space breaks the norms of a conventional Indian residence, and in one simple word can be described as “CURATED”.",
    featuredLogo: flogo6,
    scrollingImages: eclecticApartment,
  },
  {
    id: 7,
    type: 1,
    title: "Luxury Corporate Office",
    collage: collage7,
    videoUrl: mainvideo7,
    featuredImage: featuredImage7,
    media: space7,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>One BKC, Mumbai</p>",
    detail:
      "This 16000 square feet office, located in one of the premier high risers in Bandra Kurla Complex, adjacent to the west coast of Mumbai. Conceived as a single volumetric form, the challenge was to integrate four unit spaces into one. The brief was to create a non-typical yet luxurious corporate office where every suite would have its own identity and a self-contained space to host visitors. Highlighting its expanse, this space breaks the norms of a typical corporate office. Each element has taken its place in the space and created a look that is luxurious and sophisticated.",
    featuredLogo: flogo7,
    scrollingImages: luxuryCorporate,
  },
  {
    id: 8,
    type: 1,
    title: "Luxury Penthouse",
    collage: collage8,
    videoUrl: mainvideo8,
    featuredImage: featuredImage8,
    media: space8,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Pali Hill, Mumbai</p>",
    detail:
      "This private wing of a 20000-sq. ft. sea-facing penthouse spread over 3 floors of a high rise in Pali Hill, lies on the west coast of Mumbai. Conceived as a simple single volumetric form, the space is designed around an exciting lifestyle with its very own salon, spa and theatre. This penthouse embodies the very idea of curated luxury due to play of light with the space. As a result of meticulous work of design, this space turned out not only as a harmonious and functional environment but also a visual treat. ",
    featuredLogo: flogo8,
    scrollingImages: luxuryPenthouse,
  },
  {
    id: 9,
    type: 1,
    title: "Bespoke Luxury Office",
    collage: collage9,
    videoUrl: mainvideo9,
    featuredImage: featuredImage9,
    media: space9,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>One BKC, Mumbai</p>",
    detail:
      "This exquisite office suite in one of the premier high risers in Bandra Kurla Complex, adjacent to the west coast of Mumbai. The brief was to create a non-typical yet luxurious corporate office where the suite would have its own identity and a self-contained space to host visitors. Conceived as a single volumetric form, interesting details like bas-relief made for an interesting proposition. Having said that we feel this space leaves a lasting impression of sophistication, functionality, style and beauty.",
    featuredLogo: flogo9,
    scrollingImages: luxuryOfficeBKCMumbai,
  },
  {
    id: 10,
    type: 1,
    title: "Golds Gym Sector 14",
    collage: collage10,
    videoUrl: mainvideo10,
    featuredImage: featuredImage10,
    media: space10,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Gurugram</p>",
    detail:
      "Spread across 5 floors, the concept of this 9000-sq. ft. gym was derived from the streets of New York. The streetscape of New York is constantly evolving and always under construction. The inspiration came from the industrial character of Brooklyn, just about when it started to gentrify. As you walk from Manhattan to Brooklyn over any of the bridges, you see the reflection of the cityscape in the water, which just opens a whole new dimension and that is what inspired the treatment of ceilings in addition to avoid taking floor space for design elements. ",
    featuredLogo: flogo10,
    scrollingImages: goldGym14,
  },
  {
    id: 11,
    type: 1,
    title: "Plush Office",
    collage: collage11,
    videoUrl: mainvideo11,
    featuredImage: "",
    media: space11,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>One BKC, Mumbai</p>",
    detail:
      "This private office suite is in one of the premier high risers in Bandra Kurla Complex, adjacent to the west coast of Mumbai. Conceived as a single volumetric form, the interior of this luxurious office suite is a combination of major pop of colours with a strong focus on materials. Highlighting its expanse, this space breaks the norms of a typical corporate office. Each element has taken its place in the space and created a look that is luxurious and sophisticated.",
    featuredLogo: flogo11,
    scrollingImages: plush,
  },
  {
    id: 12,
    type: 1,
    title: "Eclectic Holiday Décor",
    collage: collage12,
    videoUrl: "",
    featuredImage: featuredImage12,
    media: space12,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>New Delhi</p>",
    detail:
      "Eclectic Holiday Décor – A set of unique styles and tips for you to be inspired to make yours equally fun, full of colour and panache. With the spirit soaring high with gorgeous décor and mood around the home, all you need is an eclectic mix of ideas to do up your home for the coming season. “When it comes to annual festivities, the devil lies in details,” says Sanjy Syngh. Add a touch of exotic and quirk to interior settings to achieve astonishing results is the designer’s forte.",
    featuredLogo: flogo12,
    scrollingImages: christmasStyling,
  },
  {
    id: 13,
    type: 1,
    title: "Design Studio",
    collage: collage13,
    videoUrl: mainvideo13,
    featuredImage: featuredImage13,
    media: space13,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>New Delhi</p>",
    detail:
      "Located in the now not so rustic village of Lado Sarai, New Delhi, this studio space was reimagined with a design to infuse more style and glamour that was very international gallery-like. This space breaks the norms of an interior design studio. Drawing material inspiration from the old-world Parisian residences and the colour inspirations from Parisian monuments, this space in one simple word can be described as “CURATED”. ",
    featuredLogo: flogo13,
    scrollingImages: designStudio,
  },
  {
    id: 14,
    type: 1,
    title: "Corporate Office",
    collage: collage14,
    videoUrl: mainvideo14,
    featuredImage: featuredImage14,
    media: space14,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>BKC, Mumbai</p>",
    detail:
      "This massive office is located on of the higher floors of a premier building in Bandra Kurla Complex, adjacent to the west coast of Mumbai. The interior equipped with modern furniture and lighting exudes unique contemporary glamour. Natural light bathes the space through the large windows, acting as an asset in which subtle features and surfaces anchor themselves.  The space leaves a lasting impression of the sophistication, functionality, style and beauty.",
    featuredLogo: flogo14,
    scrollingImages: corporateOffice,
  },

  {
    id: 15,
    type: 2,
    title: "Weekend Home",
    collage: collage15,
    videoUrl: "",
    featuredImage: featuredImage15,
    media: space15,
    bannerImg: spacebanner1,
    isVideo: 1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Chattarpur, New Delhi</p>",
    detail:
      "This 2.5-acre farmhouse is set in Delhi’s Urban Sprawl, Chattarpur. With 5000 square feet built up area, the idea was to relish the landscaping and the waterbodies over the weekend. The extremely crisp brief was to convert this pre-built structure in to an Urban Swiss Chalet. Basis of the composition takes dark colour, which is a rare in decoration of Indian residences. Even though it was quite a daunting task complementing the dark elements, each corner has been thoughtfully and tastefully curated to bring a sense of new. This space exudes its own stylish and young personality.",
    featuredLogo: flogo15,
    scrollingImages: WeekendHome,
  },
  {
    id: 16,
    type: 2,
    title: "Golds Gym Sector 43",
    collage: collage16,
    videoUrl: mainvideo16,
    featuredImage: featuredImage16,
    media: space16,
    isVideo: 1,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Gurugram</p>",
    detail:
      "Spread across 12000 square feet, this gym sits pretty atop Gurgaon’s   strategically located shopping complex. A scientific space layout has been developed to offer an international feel and a customized workout experience. To make this space stand out as a concept fitness facility attracting fitness lovers, the edgy design takes advantage of the freedom of space hence making it a luxe experience for the largely cosmopolitan population.",
    featuredLogo: flogo16,
    scrollingImages: goldGym43,
  },
  {
    id: 17,
    type: 2,
    title: "Housewarming",
    collage: collage17,
    videoUrl: mainvideo17,
    featuredImage: featuredImage17,
    media: space17,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>Mumbai</p>",
    detail:
      "This interior product studio by Surprise Home Linen is situated on the first floor of one of the busier lanes of suburban Mumbai. This visual merchandising project was primarily based on trend and colour forecasting. Most materials sourced from European companies that the brand represents, were seamlessly blended to exude unique glamour. With a lot of attention to detail this space turned out not only harmonious but also a visual treat.",
    featuredLogo: flogo17,
    scrollingImages: housewarming,
  },
  {
    id: 18,
    type: 2,
    title: "Luxury Office",
    collage: collage18,
    videoUrl: mainvideo18,
    featuredImage: featuredImage18,
    media: space18,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>One BKC, Mumbai</p>",
    detail:
      "The interior of this luxurious office suite combines a unique sensibility for detail and materiality with a modern sense of space and light. The interior equipped with modern furniture from Italian brands and lighting from various International brands exudes unique contemporary glamour. Not only is this space more modern and stylish but also is elegant and comfortable. In this project, our mission was to make practicality beautiful.",
    featuredLogo: flogo18,
    scrollingImages: luxuryofficeBKC,
  },
  {
    id: 19,
    type: 2,
    title: "India Design ID",
    collage: collage19,
    videoUrl: "",
    featuredImage: featuredImage19,
    media: space19,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>New Delhi</p>",
    detail:
      "This booth was designed for a furnishings company exhibiting at the INDIA DESIGN ID 2019. This project was primarily based on a mix of trend and colour forecasting and multiple interior set-ups. Most materials sourced from European companies that the company represents, were seamlessly blended to exude unique glamour. With a lot of attention to detail this space turned out not only harmonious but also a visual treat.",
    featuredLogo: flogo19,
    scrollingImages: IndiaDesignID,
  },
  {
    id: 20,
    type: 2,
    title: "Resto Bar",
    videoUrl: "",
    featuredImage: featuredImage20,
    media: space20,
    collage: collage20,
    isVideo: 0,
    bannerImg: spacebanner1,
    link: "https://www.lipsum.com/feed/html",
    text: "<p>New Delhi</p>",
    detail:
      "Conceived as a snug weekend home, a lot of structural referencing was taken from the client’s ancestral house up in the hills. Keeping the fact that weekends are all about family time, the layout is very snug and doubles as an entertaining space as its laid out like a family lounge. While there is play of scale with symmetry and balance, in terms of material specification, there is wood in rawest form on the ceiling, layers of fabrics in gorgeous jewel tones and metallic accent tables. Not only does this space leave a lasting impression, it also has the ability to retain its timeless unique character.",
    featuredLogo: "",
    scrollingImages: restroBar,
  },
];
export const ObjectListContent = [
  {
    id: 1,
    type: 1,
    title: "Lock & Key",
    media: object1,
    mediaHover: object1,
    isVideo: 0,
    text: "<p></p>",
    detail:
      "What is a lock without a key and what is a key without a lock? But ever wondered when they both mate - what goes on? This concept for this series was born out of curiosity.As to what happens when the key goes inside the lock and the mechanical functioning of it.The lock and key are represented in the form of chairs and the mechanical action is represented in the form of a bench. Made in wood and stone, this is a limited edition series.",
    scrollingImages: lockkey,
  },
  {
    id: 2,
    type: 1,
    title: "Bench",
    media: object2,
    mediaHover: object2hover,
    isVideo: 0,
    text: "<p>Made in wood and stone, this is a limited edition series. </p>",
    detail:
      "Ever wondered what happens when a lock and key mate - what goes on? This concept for this bench was born out of curiosity, As to what happens when the key goes inside the lock and the mechanical functioning of it. The lock and key are represented in the form of chairs and the mechanical action is represented in the form of a bench.",
    scrollingImages: bench,
  },
  {
    id: 3,
    type: 1,
    title: "You Are The King",
    media: object3,
    mediaHover: object3,
    isVideo: 0,
    text: "<p>MADE IN FIBER & STAINLESS STEEL.</p> <p>AVAILABLE IN ULTRAMARINE BLUE, PURE GREEN & BLACK.</p>",
    detail:
      "We all have two sides. One that you see in public and one that you see in the toilet mirror. Inspired by self-love, I consider my toilet mirror self as my king. My conversations with him are inspiring. He pushes me to trust my weirdness and grow my wings. As per him normalcy is barbed wire to human creativity. He convinces me to have a fucking opinion and continue being the best version of myself.",
    scrollingImages: king,
  },
  {
    id: 4,
    type: 1,
    media: object4,
    mediaHover: object4,
    isVideo: 0,
  },
  {
    id: 5,
    type: 1,
    title: "Parcel Me Series",
    media: object5,
    mediaHover: object5,
    isVideo: 0,
    text: "<p><span>SIZE:</span> Coffee Table: 96”L X 36”W X 23”H Pedestal: 19”W X 15”D X 39”H</p> <p><span>MATERIAL:</span>STAINLESS STEEL, BRASS & GLASS</p>",
    detail:
      "Anything I design is usually a reflection of my thoughts. With the track record of at least one flight a week, the pandemic got me confined to my home. It could not confine my thoughts. The series of furniture is inspired by the idea of my thoughts being parceled to different destinations consists of a coffee table and a pedestal. It is a thought through exercise in material and a bold typography. The upward arrow signifies happy thoughts. Brushed brass and glass come together to create bold treatment of form and exhibition of function.",
    scrollingImages: parcel,
  },
  {
    id: 6,
    type: 1,
    title: "Time To Reflect Series",
    media: object6,
    mediaHover: object6,
    isVideo: 0,
    text: "<p><span>SIZE:</span>Time to Reflect Coffee Table: 96”L X 36”W X 19.5”H Time to Reflect Mini (Side Table)l: 24”W X 24”D X 22”H </p><p><span>MATERIAL:</span>STAINLESS STEEL, BRASS & GLASS</p>",
    detail:
      "Telling an authentic story is the heartbeat of designing unique objects. Sometimes stepping away is the answer. During the ongoing pandemic a lot of us stepped away to reflect upon our lives. I was supposed to travel to Rome before visiting Salone in Milan. Since we were confined to the four walls of our home I decided to virtually envision myself in Rome and that gave birth to this series. This piece was inspired by the reflection of the Roman arches in water. It is an interplay between material and pattern. Mirrored Stainless Steel with polished brass come together to create a poetic license.",
    scrollingImages: timeReflect,
  },
  {
    id: 7,
    type: 1,
    title: "My Thinking Faces",
    media: object7,
    mediaHover: object7,
    isVideo: 0,
    text: "<p>MADE IN FIBER & STAINLESS STEEL.</p><p>AVAILABLE IN ULTRAMARINE BLUE, PURE GREEN & BLACK.</p>",
    detail:
      "In this digital day and age there’s an emoji for every expression but does it feel real. What about your real-life expressions? Your expressions are your visual voice. Your visual voice is the translation of the beautiful sound in your head. This series is an interpretation of my thinking faces, my visual voice during a creative ideation. The voice grows and so do the balls on the head which represent the thought bubble carving a beautiful sound.",
    scrollingImages: faces,
  },
];
export const PressListContent = [
  {
    id: 1,
    title: "LOREUM IPSUM",
    type: 2,
    picture: press1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine1,
    bannerVideo: "",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
  },
  {
    id: 2,
    title: "LOREUM IPSUM",
    type: 2,
    picture: press2,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine2,
    bannerVideo: "",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
  },
  {
    id: 3,
    title: "LOREUM IPSUM",
    type: 2,
    picture: press3,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine3,
    bannerVideo: "",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
  },
  {
    id: 4,
    title: "LOREUM IPSUM",
    type: 2,
    picture: press4,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine4,
    bannerVideo: "",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
  },
  {
    id: 5,
    title: "LOREUM IPSUM",
    type: 2,
    picture: press5,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine5,
    bannerVideo: "",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
  },
  {
    id: 6,
    type: 2,
  },
  {
    id: 7,
    title: "LOREUM IPSUM",
    type: 1,
    picture: press6,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine6,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "https://www.architecturaldigest.in/content/new-delhi-sanjyt-syngh-designs-a-swiss-style-chalet-inside-a-farmhouse/#s-cust0",
  },
  {
    id: 8,
    title: "LOREUM IPSUM",
    type: 1,
    picture: press7,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine7,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 9,
    title: "LOREUM IPSUM",
    type: 1,
    picture: press8,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine8,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "https://www.newindianexpress.com/lifestyle/fashion/2019/oct/14/its-time-to-create-your-very-own-salon-2047279.html",
  },
  {
    id: 10,
    title: "LOREUM IPSUM",
    type: 1,
    picture: press9,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine9,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 11,
    title: "LOREUM IPSUM",
    picture: press10,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine10,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 12,
    title: "LOREUM IPSUM",
    picture: press11,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine11,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 13,
    title: "LOREUM IPSUM",
    picture: press12,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine12,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 14,
    title: "LOREUM IPSUM",
    picture: press13,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine13,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 15,
    title: "LOREUM IPSUM",
    picture: press14,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine14,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 16,
    title: "LOREUM IPSUM",
    picture: press15,
    bannerImg: banner1,
    bannerImg2: banner2,
    type: 1,
    magzine: magzine15,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 17,
    title: "LOREUM IPSUM",
    picture: press16,
    bannerImg: banner1,
    bannerImg2: banner2,
    type: 1,
    magzine: magzine16,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 18,
    title: "LOREUM IPSUM",
    picture: press17,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine17,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 19,
    title: "LOREUM IPSUM",
    picture: press18,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine18,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 20,
    title: "LOREUM IPSUM",
    picture: press19,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine19,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 21,
    title: "LOREUM IPSUM",
    picture: press20,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine20,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 22,
    title: "LOREUM IPSUM",
    picture: press21,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine21,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 23,
    title: "LOREUM IPSUM",
    picture: press22,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine22,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 24,
    title: "LOREUM IPSUM",
    picture: press23,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine23,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 25,
    title: "LOREUM IPSUM",
    picture: press24,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine24,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 26,
    title: "LOREUM IPSUM",
    picture: press25,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine25,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "https://www.architectandinteriorsindia.com/projects/4821-sanjyt-syngh-unveils-unique-home-salon-concept",
  },
  {
    id: 27,
    title: "LOREUM IPSUM",
    picture: press26,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine26,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 28,
    title: "LOREUM IPSUM",
    picture: press27,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine27,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 29,
    title: "LOREUM IPSUM",
    picture: press28,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine28,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 30,
    title: "LOREUM IPSUM",
    picture: press29,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine29,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 31,
    title: "LOREUM IPSUM",
    picture: press30,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine30,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 32,
    title: "LOREUM IPSUM",
    picture: press31,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine31,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "https://www.architectandinteriorsindia.com/insights/18349-5-things-to-keep-in-mind-while-choosing-and-shortlisting-sculptures-for-ones-home",
  },
  {
    id: 33,
    title: "LOREUM IPSUM",
    picture: press32,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine32,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 34,
    title: "LOREUM IPSUM",
    picture: press33,
    type: 1,
    bannerImg: banner1,
    bannerImg2: banner2,
    magzine: magzine33,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 35,
    title: "LOREUM IPSUM",
    picture: press34,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine34,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
  {
    id: 36,
    title: "LOREUM IPSUM",
    picture: press35,
    bannerImg: banner1,
    type: 1,
    bannerImg2: banner2,
    magzine: magzine35,
    bannerVideo: "",
    text: "<p>Lorem ipsum dolor sit amet, <b>consectetur</b> adipiscing elit. Phasellus faucib</p>",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu urna et justo tincidunt feugiat vitae ac lorem. Proin tincidunt eget lacus sed consectetur. Pellentesque at vulputate nisi. Proin lectus odio, efficitur vitae ultrices vitae, volutpat id ipsum. Donec commodo semper neque, in vulputate libero. Maecenas consequat nisl justo, vel gravida sem accumsan eu. Donec sed est at justo dignissim mattis. Donec porta massa vel facilisis vestibulum. Vivamus pellentesque nec eros ac porta. Nulla non erat non massa feugiat pulvinar id quis orci. Nam eu odio vulputate, pretium mauris non, euismod felis. Aliquam faucibus, libero porta dapibus blandit, tellus turpis consectetur metus, at tempor felis libero eget eros.",
    link: "",
  },
];

export const AboutTabValues = [
  {
    id: 0,
    label: "CONCEPT DEVELOPMENT",
  },
  {
    id: 1,
    label: "SPACE PLANNING & DESIGN",
  },
  {
    id: 2,
    label: "TURNKEY CONTRACTING AND PROJECT MANAGEMENT",
  },
  {
    id: 3,
    label: "FURNITURE DESIGN & MANUFACTURING",
  },
  {
    id: 4,
    label: "PRODUCT SOURCING",
  },
  {
    id: 5,
    label: "INTERIOR STYLING",
  },
  {
    id: 6,
    label: "CUSTOM LIGHTING DESIGN",
  },
  {
    id: 7,
    label: "BESPOKE ART & INSTALLATIONS",
  },
];
