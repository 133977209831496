import { styles as useStyles } from './styles';
import { Typography, Container } from '@material-ui/core';
import quoteImg from '../../../assets/images/quote-2.png';
import smallLogo from '../../../assets/images/smallLogo.png';
const Quote = ({ detail }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
    <Container>
      <div className={classes.section}>
        <img src={quoteImg} alt="quote" className={classes.quoteImg} />
        <Typography className={classes.h2} variant="h2">
          EVERYTHING SHOULD HAVE A STORY.
          <span>
            <img src={smallLogo} alt="quote" className={classes.nameImg} />
          </span>
        </Typography>
      </div>
      </Container>
    </div>
  );
};
export default Quote;
