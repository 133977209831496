import { makeStyles } from "@material-ui/core/styles";
import Facebook from "../../assets/images/facebook-black.svg";
import Vimeo from "../../assets/images/vimeo-black.svg";
import Instagram from "../../assets/images/instagram-black.svg";
import FacebookB from "../../assets/images/facebook-black.png";
import VimeoB from "../../assets/images/vimeo-black.png";
import InstagramB from "../../assets/images/instagram-black.png";

export const styles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 50,
    position:'relative',

       '&::before': {
         content: '""',
    maxWidth: '90%',
    width: '100%',
    left: 0,
    right: 0,
    bottom: "calc(100% - 2px)",
    backgroundColor: "#fff",
    position: "absolute",
    margin: "auto",
    height: "20px",
      [theme.breakpoints.down("xs")]: {
maxWidth:'100%',
    },
      },


  
  },
  inforList: {
    listStyle: "none",
    display: "flex",
    paddingLeft: 0,
    justifyContent: "center",
    flexWrap: "wrap",
  },
  subText: {
    color: "#010101",
    fontSize: "14px",
    padding: "0 20px",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.primary.blue,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      padding: "0 10px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      padding: "0 25px",
    },
  },
  subTextUl: {
    textAlign: "center",
    fontFamily: "Philosopher",
    textTransform: "uppercase",
    fontSize: "11px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  menuSocialBtn: {
    padding: 0,
    display: "inline-block",
    transition: "all ease 0.2s",
    "&:hover": {
      menuSocialBtnImg: {
        display: 'none',
      }
    },
  },
  menuSocialBtnImg: {
    width: "60px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60px",
    },
  },
  menuSocial: {
    textAlign: "right",
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      justifyContent: 'center',
    },
  },
  FooterLogos: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  facebook: {
    backgroundImage: `url(${Facebook})`,
    display: "block",
    height: "25px",
    width: "35px",

    "&:hover": {
      backgroundImage: `url(${FacebookB})`,
    },
  },
  instagram: {
    backgroundImage: `url(${Instagram})`,
    display: "block",
    height: "25px",
    width: "35px",

    "&:hover": {
      backgroundImage: `url(${InstagramB})`,
    },
  },
  vimeo: {
    backgroundImage: `url(${Vimeo})`,
    display: "block",
    width: "73px",
    height: "9vh",
    backgroundRepeat: "no-repeat",
    "&:hover": {
      backgroundImage: `url(${VimeoB})`,
      display: "block",
      width: "73px",
      height: "9vh",
      backgroundRepeat: "no-repeat",
    },
  },
}));
