import { Typography } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import PropTypes from "prop-types";
const DetailFullImage = ({ item: { featuredImage } }) => {
  const classes = useStyles();
  return (
    <div className={classes.contactImageContainer}>
      {featuredImage && <img src={featuredImage} alt="featured" />}
      <Typography className={classes.text} component="div"></Typography>
    </div>
  );
};
DetailFullImage.propTypes = {
  text: PropTypes.string,
};
export default DetailFullImage;
