import React from "react";
import classNames from "classnames";
import {
  Typography,
  withWidth,
  Container,
  Grid,
  Hidden,
} from "@material-ui/core";
import Logo from "../../../assets/images/logo-xl.jpg";
import bunnyy from "../../../assets/images/bunnyy.gif";
import { styles as useStyles } from "./styles";
const DetailText = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" justify="center">
          <Grid item md={11}>
            <Grid container direction="row" alignItems="center">
              <Grid item sm={7}>
                <Hidden xsDown>
                  <img src={Logo} alt="" />
                </Hidden>
              </Grid>
              <Grid item sm={5} className={classes.padding}>
                <div className={classes.paddingLeft}>
                  <Typography className={classes.h2} variant="h2">
                    WHO ARE WE
                  </Typography>

                  <Typography className={classes.p}>
                    We are a New Delhi-based but globally minded design
                    consultancy dedicated to luxury spaces. We take pride in
                    creating spaces that are a visual delight yet functional. We
                    consider ourselves as an interpreter. It is our passion that
                    gives us the strength to create bespoke, sophisticated
                    spaces.
                  </Typography>
                  <Typography className={classes.p}>
                    We use the power of layering different textures and mediums
                    to create a space that reflects the personality of its
                    users, by working with our exclusive team of craftsmen and
                    vendors from across the world to deliver a functional work
                    of art. We aim for perfection in every inch through
                    detailing every millimeter which is why we believe that a
                    perfectly tailored, polished and precise space reflects who
                    you are.
                  </Typography>
                </div>
                <Hidden smUp>
                  <div className={classes.paddingTop}>
                    <Typography
                      className={classNames(classes.textLeft, classes.h2)}
                      variant="h2"
                    >
                      We are a mix of influences!
                    </Typography>
                    <Typography
                      className={classNames(classes.textLeft, classes.h2)}
                      variant="h2"
                    >
                      Everything should have a{" "}
                      <span className={classes.Blue}> story! </span>
                    </Typography>
                    <Typography
                      className={classNames(classes.textLeft, classes.h2)}
                      variant="h2"
                    >
                      Do not be afraid of ideas!
                    </Typography>

                    <img src={bunnyy} alt="" className={classes.rabitFull} />
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withWidth()(DetailText);
