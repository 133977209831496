import React from "react";
import { Grid, Container } from "@material-ui/core";
import SpaceList from "../../components/Space/SpaceList/SpaceList";
import ContactImage from "../../components/Space/ContactImage/ContactImage";
import Instagram from "../../components/Common/InstagarmWidget/Insta";
import Quote from "../../components/Space/Quote/Quote";
import { SpaceListContent } from "../../utils/data";
import { makeStyles } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import SanjytLogo from "../../components/Common/SanjytLogo";
import { SPACE_TYPES } from "../../utils/common";
const styles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    overflow: "hidden",
  },
}));

const Space = () => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={2} xs={10}>
            <SanjytLogo />
          </Grid>

          <Grid item md={9} xs={12}>
            <SpaceList
              list={SpaceListContent.filter(
                (item) => item.type === SPACE_TYPES.TOP_CONTENT
              )}
            />
          </Grid>
        </Grid>
      </Container>
      <ContactImage
        text="members of the press, producers, fellow designers, and makers of all
        types are encouraged to contact us"
      />
      <Quote />
      <Container className="my-60">
        <Grid container spacing={4}>
          <Grid item md={2} xs={10}></Grid>

          <Grid item md={9} xs={12}>
            <SpaceList
              list={SpaceListContent.filter(
                (item) => item.type === SPACE_TYPES.BOTTOM_CONTENT
              )}
            />
          </Grid>
        </Grid>
      </Container>
      <Instagram />
      <Footer />
    </div>
  );
};

export default Space;
