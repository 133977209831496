import { makeStyles } from "@material-ui/core/styles";
export const styles = makeStyles((theme) => ({
  main: {
    margin: "50px 0 60px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0 30px",
    },
  },

  h2: {
    color: theme.palette.other.black,
    fontSize: "60px",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: "30px",
    letterSpacing: "-1px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
       fontSize: "40px",
    },
  },
  p: {
    fontFamily: "Philosopher",
    color: theme.palette.other.black,
    lineHeight: "13px",
    fontSize: "14px",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "12px",
      textAlign: "center",
    },
  },
  h6: {
    fontFamily: "Philosopher",
    color: "#0b0b0b",
    marginBottom: "15px",
  },
}));
