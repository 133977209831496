import React, { useEffect, useState } from "react";
import { withWidth } from "@material-ui/core";
import Rabbit from "../../../assets/images/rabit.png";
import Arrow from "../../../assets/images/arrow-up.png";
import { styles as useStyles } from "./styles";
import Draggable from "react-draggable";

const DragRabbit = (props) => {
  let [activeDrags, setActiveDrags] = useState(0);
  let [deltaPosition, setdeltaPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (deltaPosition.y.toFixed(0) < -190) {
      setUp(false);
    }
    if (deltaPosition.y.toFixed(0) > -170) {
      setUp(true);
    }
  }, [deltaPosition]);
  const onStart = () => {
    const upddatedValue = ++activeDrags;
    setActiveDrags(upddatedValue);
  };
  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setdeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };
  const onStop = () => {
    const upddatedValue = --activeDrags;
    setActiveDrags(upddatedValue);
  };

  const { up, setUp } = props;
  const classes = useStyles();
  const dragHandlers = { onStart: onStart, onStop: onStop };
  return (
    <div className={classes.root}>
      <div className={classes.DragUp}>
        <div className={classes.Black}>
          {up ? (
            <div className={classes.DragMe}>
              <img className={classes.Up} src={Arrow} alt="drag" /> drag me up
            </div>
          ) : (
            <div className={`${classes.DragMe} ${classes.DragMeDown}`}>
              drag me Down{" "}
              <img
                className={`${classes.Up} ${classes.Down}`}
                src={Arrow}
                alt="arrow"
              />
            </div>
          )}
        </div>
        <Draggable
          axis="y"
          onDrag={handleDrag}
          bounds={{ top: -350, bottom: 10, parent: "parent" }}
          {...dragHandlers}
        >
          <div className={classes.RabbitImg}>
            {/* <div className="handleRabbit">Drag from here</div> */}
            <img draggable="false" src={Rabbit} alt="Rabbit" />
          </div>
        </Draggable>
      </div>
    </div>
  );
};
export default withWidth()(DragRabbit);
