export const styles = () => ({
  sliderArrow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    marginTop: 0,
    padding: 0,
  },
  slideBtn: {
    position: "relative !important",
    top: -50,
    left: 25,
    zIndex: 1,
    display: "inline-flex",
  },
  blankButton: {
    backgroundColor: "transparent",
    border: 0,
    cursor: "pointer",
    outline: "none !important",
    transition: "all linear 0.2s",
    "& img": {
      width: 70,
      height: 70,
    },
  },
  spaceContent: {
    fontSize: 15,
    textTransform: "uppercase",
    textAlign: "center",
    transition: "all linear 0.2s",
    display: "flex",
    justifyContent: "center",
    "& div": { marginTop: 10 },
    "& p": { marginTop: 10 },
  },
  main: {
    padding: "40px 0 40px 0",
  },
  numberCount: {
    fontSize: 18,
    fontFamily: "Philosopher",
    marginTop: -45,
    marginLeft: 88,
    letterSpacing: 2,
  },
});
