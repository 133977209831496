import React, { useState } from "react";
import {
  Typography,
  withWidth,
  Container,
  Grid,
  Hidden,
} from "@material-ui/core";
import DragRabbit from "../DragRabbit/Rabbit";
import SpaceOne from "../../../assets/images/space-img-1.png";
import SpaceTwo from "../../../assets/images/space-img-2.jpg";
import MobileSpaceTwo from "../../../assets/images/mobilespace-img-2.png";
import AmLogo from "../../../assets/images/am.svg";
import Quote from "../../../assets/images/quote-1.png";
import { styles as useStyles } from "./styles";
const Brand = (props) => {
  const classes = useStyles();
  const [up, setUp] = useState(false);
  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" justify="center">
          <Grid item xs={9}>
            <Grid container direction="row">
              {!up ? (
                <Grid item xs={12} className={classes.padding}>
                  <Grid container direction="row">
                    <Grid item sm={12}>
                      <div>
                        <Hidden xsDown>
                          <img src={SpaceTwo} alt="" />
                        </Hidden>
                        <Hidden smUp>
                          <img src={MobileSpaceTwo} alt="" />
                        </Hidden>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.padding}>
                  <Grid container direction="row">
                    <Grid item sm={6}>
                      <div className={classes.paddingLeft}>
                        <img src={Quote} alt="Quote" />
                        <div className={classes.margin}></div>
                        <Typography
                          className={classes.h2}
                          variant="h2"
                          component="h2"
                        >
                          {" "}
                          a <span className={classes.blue}> space </span> should
                          reflect your style and be curation of what you love
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <div>
                        <img src={SpaceOne} alt="" />
                      </div>
                      <Grid container direction="row">
                        <Grid item md={12} className={classes.flex}>
                          <Typography
                            className={classes.p}
                            component="p"
                            style={{ marginTop: 10 }}
                          >
                            TOP 100 Interior Designers in the World |
                            <strong> Andrew Martin </strong>
                            interior Design Review
                          </Typography>
                          <img src={AmLogo} alt="" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <DragRabbit up={up} setUp={setUp} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default withWidth()(Brand);
