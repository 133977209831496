export const styles = () => ({
	    p: {
    textTransform: "uppercase",
    lineHeight: "16px",
    fontFamily: "Be Vietnam",
    letterSpacing:0,
    fontSize: "14px",
  },

});
