import React from "react";
import {
  Typography,
  withWidth,
  Container,
  Grid,
  Hidden,
} from "@material-ui/core";
import AboutImg from "../../../assets/images/Top_image.png";
import bunnyy from "../../../assets/images/bunnyy.gif";
import { styles as useStyles } from "./styles";
import Carousel from "../Carousel/Carousel";
import SanjytLogo from "../../Common/SanjytLogo";
const Banner = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" alignItems="flex-start" spacing={4}>
          <Grid item md={2} xs={10}>
            <SanjytLogo />
          </Grid>

          <Grid item md={10} xs={12}>
            <Grid
              className={classes.marginTop}
              container
              direction="row"
              spacing={4}
            >
              <Grid item md={6} sm={6} xs={12}>
                <img src={AboutImg} alt="" className={classes.AboutImg} />
                <Carousel />
              </Grid>
              <Grid item md={5} sm={6} xs={12}>
                <Hidden xsDown>
                  <div className={classes.padding}>
                    <Typography className={classes.h2} variant="h2">
                      We are a mix of influences!
                    </Typography>
                    <Typography className={classes.h2} variant="h2">
                      Everything should have a story!
                    </Typography>
                    <Typography className={classes.h2} variant="h2">
                      Do not be afraid of ideas!
                    </Typography>

                    <img src={bunnyy} alt="" className={classes.rabitFull} />
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withWidth()(Banner);
