import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  root: {
    marginTop: "40px",
    paddingTop: 0,
    paddingBottom: 40,
    position: "relative",
  },
  rootInner: {
    position: "absolute",
    left: "0",
    top: "0",
    [theme.breakpoints.down("xs")]: {
      top: "-30px",
      maxWidth: "36vw",
    },
  },
  videoCard: {
    boxShadow: "none",
    borderRadius: 0,
    position: "relative",
  },
  video: {
    width: "100%",
    height: 480,
    objectFit: "cover",
    position: "relative",
    zIndex: 20,
    [theme.breakpoints.down("md")]: {
      height: 420,
    },
    [theme.breakpoints.down("xs")]: {
      height: "70vw",
    },
  },
  link: {
    display: "block",
  },
  h2: {
    color: theme.palette.other.black,
    fontSize: "45px",
    lineHeight: "36px",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: 15,
    letterSpacing: "-1px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "45px",
      lineHeight: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "55px",
      lineHeight: "56px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "65px",
      lineHeight: "60px",
    },
  },
  p: {
    fontFamily: "Philosopher",
    fontSize: "14px",
    color: "#000000",
    lineHeight: "13px",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "12px",
    },
  },
  paddingLeft: {
    paddingLeft: theme.spacing(7),
    paddingRight: 10,
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
      marginLeft: "auto",
      paddingRight: "30px",
    },

    position: "relative",
    height: "100%",
  },
  blue: {
    color: theme.palette.primary.blue,
  },
  perfectionBtn: {
    position: "absolute",
    bottom: 40,
    left: -60,
    zIndex: "99",
    [theme.breakpoints.down("xs")]: {
      position: "static",
      marginTop: "50px",
    },
  },
}));
