import React from "react";
import { Typography, withWidth, Grid } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import { USER_EMAIL, USER_PHONE } from "../../../utils/data";
const ContactAddress = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center">
        <Grid item md={10}>
          <Grid container direction="row" alignItems="center">
            <Grid item sm={6} xs={12} className={classes.padding}>
              <Typography className={classes.h5} gutterBottom>
                Khasra No 289 Ground Floor
              </Typography>

              <Typography className={classes.h5} gutterBottom>
                M.G. Road, Sultanpur, New Delhi – 110030
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.padding}>
              <div className={classes.paddingLeft}>
                <Typography className={classes.h3}>
                  <Typography className={classes.h5} variant="span">
                    {" "}
                    T:
                  </Typography>
                  <a href={`tel:${USER_PHONE}`}>{USER_PHONE}</a>
                </Typography>
                <Typography className={classes.h5}>
                  E: <a href={`mailto:${USER_EMAIL}`}>{USER_EMAIL}</a>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withWidth()(ContactAddress);
