import { makeStyles } from "@material-ui/core/styles";
//import { Autocomplete } from "@material-ui/lab";
import BackImage from "../../../assets/images/contact-bg.jpg";
export const styles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundImage: `url(${BackImage})`,
    padding: "20px 0 60px",
  },
  h2: {
    fontFamily: "Staatliches",
    fontSize: "60px",
    color: "#fff",
    textAlign: "center",
    lineHeight: "60px",
    position: "relative",
    order: "1",
    [theme.breakpoints.down("xs")]: {
      fontSize: "45px",
      lineHeight: "45px",
    },
  },
}));
