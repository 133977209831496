import ObjectItem from "./ObjectItem";
import { styles as useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
const ObjectList = ({
  list,
  hideIndex = null,
  isVideo = false,
  noPadding = false,
  history,
}) => {
  const classes = useStyles();
  const handleObjectClick = (id) => {
    history.push({ pathname: `/object/${id}`, state: { id } });
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        className={
          noPadding ? classes.containerMainNoTopPadding : classes.containerMain
        }
      >
        {list.map((objectItem, index) => {
          return (
            <Grid item md={6} sm={6} xs={12} key={index}>
              {index === 3 ? (
                <ObjectItem
                  item={objectItem}
                  key={objectItem.id}
                  classes={classes}
                  arrow="false"
                  // onClick={() => handleObjectClick(objectItem.id)}
                />
              ) : (
                <ObjectItem
                  item={objectItem}
                  key={objectItem.id}
                  classes={classes}
                  onClick={() => handleObjectClick(objectItem.id)}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

ObjectList.propTypes = {
  list: PropTypes.array.isRequired,
  hideIndex: PropTypes.number,
  isVideo: PropTypes.bool,
  noPadding: PropTypes.bool,
};
export default withRouter(ObjectList);
