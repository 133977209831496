import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { withStyles, Typography, Hidden, withTheme } from "@material-ui/core";
import ArrowLeft from "../../../assets/images/arrow-left.svg";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import WOW from "wowjs";
import "react-medium-image-zoom/dist/styles.css";
import { styles } from "./styles";
class ObjectCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 1,
      speed: 500,
      arrows: false,
      dots: false,
      autoplay: false,
      autoplaySpeed: 3000,
      afterChange: this.handleAfterChange,
    };
    const { classes, item } = this.props;
    return (
      <div className={classes.main}>
        <div>
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {item &&
              item.scrollingImages &&
              item.scrollingImages.map((n, index) => {
                return (
                  <div key={index}>
                    <div className={classes.positionRelative}>
                      {n.type === 2 ? (
                        <video
                          playsInline
                          loop
                          autoPlay
                          muted
                          src={n.src}
                          className={classes.imgobject}
                        />
                      ) : (
                        <img
                          className="spaceimg-bn"
                          alt="banner1"
                          src={n.src}
                        />
                      )}
                      {/* <div className={classes.overlayBottom}>
                        <h2 className="h2-heading">{item.title}</h2>
                        <Typography className="h6-heading" component="h4">
                          <div
                            className={classes.pressText}
                            dangerouslySetInnerHTML={{
                              __html: item.text,
                            }}
                          />
                        </Typography>
                      </div> */}
                    </div>
                  </div>
                );
              })}
          </Slider>
          <Hidden smUp>
            <div>
              <h2 className="h2-heading1">{item.title}</h2>

              <Typography className={classes.p} component="div">
                <div
                  className={classes.pressText}
                  dangerouslySetInnerHTML={{
                    __html: item.detail,
                  }}
                />
              </Typography>
              <Typography className="h6-heading1" component="h4">
                <div
                  className={classes.pressText}
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              </Typography>
            </div>
          </Hidden>
          <div className={classes.sliderArrow}>
            <div className={classes.numberCount}>
              {this.state.currentSlide + 1}/{" "}
              {item.scrollingImages && item.scrollingImages.length}
            </div>
            <button className="blank-button spaceCbtn" onClick={this.previous}>
              <img alt="ArrowRight" src={ArrowRight} />
            </button>
            <Hidden xsDown>
              <div>
                <h2 className="h2-heading1">{item.title}</h2>

                <Typography className={classes.p} component="div">
                  <div
                    className={classes.pressText}
                    dangerouslySetInnerHTML={{
                      __html: item.detail,
                    }}
                  />
                </Typography>
                <Typography className="h6-heading1" component="h4">
                  <div
                    className={classes.pressText}
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                </Typography>
              </div>
            </Hidden>
            <button
              className="blank-button spaceCbtn"
              style={{ marginLeft: "auto" }}
              onClick={this.next}
            >
              <img alt="ArrowLeft" src={ArrowLeft} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ObjectCarousel);
