import React, { useEffect, useRef } from "react";
import {
  Typography,
  withWidth,
  Card,
  Container,
  Grid,
} from "@material-ui/core";
import Video from "../../../assets/video/grid-animation.mp4";
import KnowMorebtn from "../../Common/Button/KnowButton";
import Image from "../../../assets/images/perfection-bg.jpg";
import { styles as useStyles } from "./styles";

const Perfection = (props) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div className="wow fadeInLeft" data-wow-duration="2s">
          <img src={Image} alt="" />
        </div>
      </div>
      <Container>
        <Grid container direction="row" justify="center">
          <Grid item md={11}>
            <Grid container direction="row-reverse" spacing={4}>
              <Grid item sm={4} xs={8}>
                <div className={classes.paddingLeft}>
                  <Typography className={classes.h2} variant="h2">
                    we aim at <span className={classes.blue}> perfection </span>
                    in every inch
                  </Typography>{" "}
                  <Typography className={classes.p} gutterBottom>
                    Spaces tell a story. With our exclusive team of craftsmen
                    and vendors from across the world, we deliver a
                    globally-inspired, functional work of art.
                  </Typography>
                  <a href="/about">
                    <div className={classes.perfectionBtn}>
                      <KnowMorebtn />
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item sm={6}>
                <Card className={classes.videoCard}>
                  <video
                    playsInline
                    loop
                    muted
                    src={Video}
                    ref={videoEl}
                    className={classes.video}
                  />
                  <div className="dot-blink"></div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withWidth()(Perfection);
