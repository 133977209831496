import React, { useEffect, useRef } from "react";
import arrowRight from "../../../assets/images/arrow-left.svg";
import { Typography } from "@material-ui/core";
const SpaceItem = ({ item, classes, onClick }) => {
  const { media, text, title, isVideo } = item;
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div className={classes.spaceItem} onClick={onClick}>
      {isVideo === 1 ? (
        <video
          playsInline
          loop
          autoPlay
          muted
          src={media}
          ref={videoEl}
          className={classes.imgobject}
        />
      ) : (
        <img src={media} alt="space" />
      )}

      <div className={classes.overlayHeading}>
        <Typography className={classes.h2} component="h2">
          <div
            className={classes.pressText}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Typography>
      </div>
      <div className={classes.overlay}>
        <div className={classes.arrow}>
          <img className={classes.arrowRight} src={arrowRight} alt="" />
        </div>
        <div
          className={classes.spaceText}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    </div>
  );
};
export default SpaceItem;
