import React from "react";
import { Typography, withWidth, Grid, Container } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import arrowRight from "../../../assets/images/arrow-left.svg";
import scrollDown from "../../../assets/images/space/scroll-down.png";
const FullWidthImage = (props) => {
  const classes = useStyles();
  return (
    <Container className={classes.P0}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <div className={classes.contactImageContainer}>
            <Typography className={classes.text} component="div">
              WE ARE DEVOTED TO APPLYING OUR PHILOSOPHY TO DESIGN. WE ARE HAPPY
              TO MEET YOU IN OUR APPOINTMENT BASED STUDIO IN NEW DELHI TO
              ENVISION YOUR SPACE.
            </Typography>
          </div>
        </Grid>
        <Grid item md={2}>
          <a href="/contact">
            <img src={arrowRight} alt="arrowRight" className={classes.arrow} />
          </a>
        </Grid>
      </Grid>

      <img
        src={scrollDown}
        alt="scrolldown"
        className="ScrollDown ScrollDownSpace"
      />
    </Container>
  );
};
export default withWidth()(FullWidthImage);
