import SpaceItem from "./SpaceItem";
import { styles as useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
const SpaceList = ({
  list,
  hideIndex = null,
  isVideo = false,
  noPadding = false,
  history,
}) => {
  const classes = useStyles();
  const handleSpaceClick = (id) => {
    history.push({ pathname: `/space/${id}`, state: { id } });
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        className={
          noPadding ? classes.containerMainNoTopPadding : classes.containerMain
        }
      >
        {list.map((spaceItem, index) => {
          return (
            <Grid item md={6} sm={6} xs={12} key={index}>
              <SpaceItem
                item={spaceItem}
                key={spaceItem.id}
                classes={classes}
                onClick={() => handleSpaceClick(spaceItem.id)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

SpaceList.propTypes = {
  list: PropTypes.array.isRequired,
  hideIndex: PropTypes.number,
  isVideo: PropTypes.bool,
  noPadding: PropTypes.bool,
};
export default withRouter(SpaceList);
