import React from "react";
import { Grid, Link, Container, Hidden } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import Facebook from "../../assets/images/facebook.png";
import Vimeo from "../../assets/images/vimeo.png";
import Instagram from "../../assets/images/instagram.png";
import FacebookDot from "../../assets/images/facebook-black.png";
import VimeoDot from "../../assets/images/vimeo-black.png";
import InstagramDot from "../../assets/images/instagram-black.png";
import FooterLogo from "./../../assets/images/footer-logo.png";
import { FACEBOOK_URL, INSTAGRAM_URL, VIMEO_URL } from "../../utils/data";
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item sm={11} xs={12}>
            <Grid
              container
              direction="row-reverse"
              justify="center"
              alignItems="center"
            >
              <Grid item sm={3} xs={12}>
                <div className={classes.menuSocial}>
                  <Link
                    href={`${FACEBOOK_URL}`}
                    target="_blank"
                    rel="noreferrer"
                    className="menuSocialBtn"
                  >
                    <img
                      alt="Facebook"
                      className="menuSocialBtnImg"
                      src={Facebook}
                    />
                    <img
                      alt="Facebook"
                      className="menuSocialBtnImg1"
                      src={FacebookDot}
                    />
                  </Link>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                    className="menuSocialBtn"
                  >
                    <img
                      alt="Instagram"
                      className="menuSocialBtnImg"
                      src={Instagram}
                    />
                    <img
                      alt="Instagram"
                      className="menuSocialBtnImg1"
                      src={InstagramDot}
                    />
                  </Link>
                  <Link
                    href={`${VIMEO_URL}`}
                    target="_blank"
                    rel="noreferrer"
                    className="menuSocialBtn"
                  >
                    <img alt="Vimeo" className="menuSocialBtnImg" src={Vimeo} />
                    <img
                      alt="Vimeo"
                      className="menuSocialBtnImg1"
                      src={VimeoDot}
                    />
                  </Link>
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className={classes.subTextUl}>
                  <ul className={classes.inforList}>
                    <li>
                      <Link href="/" className={classes.subText}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link href="/about" className={classes.subText}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link href="/space" className={classes.subText}>
                        Spaces
                      </Link>
                    </li>
                    <li>
                      <Link href="/object" className={classes.subText}>
                        Objects
                      </Link>
                    </li>
                    <li>
                      <Link href="/press" className={classes.subText}>
                        Press
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact" className={classes.subText}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <Hidden xsDown>
                    <p className={classes.subTextUl}>
                      ©sanjyt syngh 2021, all rights reserved.
                    </p>
                  </Hidden>
                </div>
              </Grid>
              <Grid item sm={3} xs={12} align="center">
                <div className={classes.FooterLogos}>
                  <img src={FooterLogo} alt="FooterLogo" />
                </div>

                <Hidden smUp>
                  <p className={classes.subTextUl}>
                    ©sanjyt syngh 2021, all rights reserved.
                  </p>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Footer;
