import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Container, Grid, Tab, Tabs, Box } from "@material-ui/core";
import service1 from "../../../assets/images/services/desktop/service1.jpg";
import service2 from "../../../assets/images/services/desktop/service2.jpg";
import service3 from "../../../assets/images/services/desktop/service3.jpg";
import service4 from "../../../assets/images/services/desktop/service4.jpg";
import service5 from "../../../assets/images/services/desktop/service5.jpg";
import service6 from "../../../assets/images/services/desktop/service6.jpg";
import service7 from "../../../assets/images/services/desktop/service7.jpg";
import service8 from "../../../assets/images/services/desktop/service8.jpg";
import imageA1 from "../../../assets/images/services/desktop/imageA1.png";
import imageA2 from "../../../assets/images/services/desktop/imageA2.png";
import imageA3 from "../../../assets/images/services/desktop/imageA3.png";
import { styles as useStyles } from "./styles";
import { AboutTabValues } from "../../../utils/data";
import WOW from "wowjs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const renderImage = () => {
    switch (value) {
      case 0:
        return (
          <div className={classes.tabsThree}>
            <img
              src={imageA2}
              alt="service1"
              className={`${classes.imageA1} wow fadeInDown`}
              data-wow-duration="2s"
              data-wow-delay="0s"
              style={{
                display: "block",
                margin: " auto",
                marginBottom: "-50px",
                zIndex: "1",
                position: "relative",
              }}
            />
            <img
              src={imageA1}
              alt="service2"
              className={`${classes.imageA2} wow fadeInDown`}
              data-wow-duration="2s"
              data-wow-delay="0.5s"
              style={{ marginTop: " -250px", position: "relative", zIndex: 2 }}
            />
            <img
              src={imageA3}
              alt="service3"
              data-wow-duration="2s"
              data-wow-delay="0.10s"
              className={`${classes.imageA3} wow fadeInDown`}
              style={{ top: " -90px", position: "relative", left: "80px" }}
            />
          </div>
        );
      case 1:
        return <img src={service2} alt="service2" />;
      case 2:
        return <img src={service3} alt="service3" />;
      case 3:
        return <img src={service4} alt="service4" />;
      case 4:
        return <img src={service5} alt="service5" />;
      case 5:
        return <img src={service6} alt="service6" />;
      case 6:
        return <img src={service7} alt="service7" />;
      case 7:
        return <img src={service8} alt="service8" />;

      default:
        return <img src={service1} alt="service1" />;
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" justify="center">
          <Grid item md={11}>
            <Grid container direction="row" alignItems="center">
              <Grid item sm={4} className={classes.padding}>
                <div className={classes.paddingLeft}>
                  <div className={classes.height}></div>
                  <Typography className={classes.p} gutterBottom>
                    A lot of design is story telling. We believe in writing
                    stories. Stories that are based on client brief. Stories
                    that are expressed through different mediums. The Studio’s
                    expertise is applied to a range of services from residential
                    to retail, corporate to commercial. Even though we design
                    spaces, our involvement crosses over different disciplines
                    of designs mentioned below, combined with our strengths to
                    bring sketches to reality.
                  </Typography>
                  <div className={classes.height}></div>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    aria-label="Services"
                    className={classes.tabs}
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    classes={{
                      indicator: classes.indicator,
                    }}
                  >
                    {AboutTabValues.map(({ id, label }) => {
                      return (
                        <Tab
                          key={id}
                          onMouseOver={() => handleChange(id)}
                          disableRipple={true}
                          tabindicator="true"
                          className={classes.tabBtn}
                          label={label}
                          {...a11yProps(id)}
                        />
                      );
                    })}
                  </Tabs>
                </div>
              </Grid>
              <Grid item sm={8} className={classes.padding}>
                <TabPanel value={value} index={value}>
                  {renderImage()}
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
