import { Grid, Container, Typography } from "@material-ui/core";
import { styles as useStyles } from "./styles";
const DetailText = ({ item: { title, detail, link, collage } }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} md={11}>
            <Typography className={classes.h2} variant="h2">
              {title}
            </Typography>
            {collage && (
              <img
                src={collage}
                alt="barber"
                width="100%"
                className={classes.barberImg}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default DetailText;
