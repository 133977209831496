import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  root: {
    marginTop: "40px",
    paddingTop: 0,
    paddingBottom: 40,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
       marginTop: "20px",
       paddingBottom: 0,
    },
  },

  h2: {
    color: theme.palette.other.black,
    fontSize: "50px",
    lineHeight: "45px",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    fontWeight:'800',
    marginBottom: "30px",
    letterSpacing: "-1px",
    textAlign:'center',
  },
  textLeft:{
  [theme.breakpoints.down("xs")]: {
       textAlign:'left',
    },
 },
  p: {
    fontFamily: "Philosopher",
    color: theme.palette.other.black,
    lineHeight: "13px",
    fontSize: "14px",
    marginBottom: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "12px",
      textAlign: "justify",
      textAlignLast: "center",
    },
  },
  height: {
    height: "10px",
  },
  padding: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
  },
  Blue:{
    color: theme.palette.primary.blue,
  },
  paddingTop:{
    paddingTop:'50px',
    "& h2":{
      fontWeight:'500',
    },
  },
  rabitFull:{
    marginTop:'-80px',
  },

}));
