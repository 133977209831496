import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  contactImageContainer: {
    width: "100%",
    "& img": {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {},
  },
  text: {
    height: "100%",
    color: theme.palette.other.white,
    display: "flex",
    alignItems: "center",
    fontSize: 30,
    lineHeight: "35px",
    textTransform: "uppercase",
    maxWidth: 790,
    left: "0",
    right: "0",
    margin: "auto",
    position: "relative",
    fontFamily: "Philosopher",
    fontWeight: 400,
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: 21,
      padding: 10,
      justifyContent: "center",
      width: "100%",
      height: "100%",
      left: 0,
    },
  },
  arrow: {
    width: 70,
    marginTop: 50,
    filter: "invert(1)",
    [theme.breakpoints.down("sm")]: {
      width: 50,
    },
  },
}));
