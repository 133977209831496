import { makeStyles } from "@material-ui/core/styles";
export const styles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  DragUp: {
    height: "100%",
    position: "relative",
  },

  Black: {
    width: "40px",
    height: "100%",
    margin: "auto",
    backgroundColor: theme.palette.other.black,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "20px",
      // height: "100vh",
    },
  },
  RabbitImg: {
    position: "absolute",
    left: 3,
    right: 0,
    bottom: "40px",
    margin: "auto",
    textAlign: "center",
    cursor: "pointer",
    userDrag: "none",
    width: "20vh",
    [theme.breakpoints.down("xs")]: {
      bottom: "25%",
      width: "7vh",
    },
  },
  RabbitImgUp: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "40px",
    margin: "auto",
    textAlign: "center",
    cursor: "pointer",
    userDrag: "none",
    [theme.breakpoints.down("xs")]: {
      top: "25%",
    },
  },
  DragMe: {
    fontFamily: "Philosopher",
    fontSize: "20px",
    color: theme.palette.other.white,
    textTransform: "uppercase",
    transform: "rotate(90deg)",
    transformOrigin: " -15% 155%",
    width: "226px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    margin: "auto",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      top: "13%",
      transformOrigin: "-14% 158%",
    },
  },
  DragMeDown: {
    transformOrigin: " 28% -55%",
    bottom: "160px",
    top: "inherit",
    width: "257px",
    [theme.breakpoints.down("xs")]: {
      bottom: "290px",
      top: "inherit",
      transformOrigin: "-12% 158%",
    },
  },

  Up: {
    transform: "rotate(-90deg) translateX(-10px) translateY(-25px)",
    width: "32px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "20px",
    },
  },
  Down: {
    transform: "rotate(90deg) translateX(7px) translateY(-30px)",
    width: "32px",
    [theme.breakpoints.down("xs")]: {
      width: "20px",
    },
  },
}));
