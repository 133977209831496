import { makeStyles } from "@material-ui/core/styles";
import FacebookW from "../../assets/images/social/FACEBOOK_WHITE.svg";
import Facebook from "../../assets/images/social/FACEBOOK_GREY.svg";
import InstagramW from "../../assets/images/social/INSTAGRAM_WHITE.svg";
import Instagram from "../../assets/images/social/INSTAGRAM_GREY.svg";
import VimeoW from "../../assets/images/social/VIMEO_WHITE.svg";
import Vimeo from "../../assets/images/social/VIMEO_GREY.svg";
export const styles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    alignItems: "flex-start",
    justifyContent: "center",
    minHeight: "auto",
  },
  title: {
    paddingTop: 2,
    fontFamily: "Milkshake",
    fontSize: 38,
    marginLeft: "auto",
  },
  menu: {
    paddingTop: 0,
    marginLeft: "auto",
  },
  menuSocial: {
    marginRight: "100px",
    marginLeft: "auto",
    display: "flex",
    paddingTop: "5px",
    paddingBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      // marginTop: "150px",
      position: "absolute",
      bottom: 20,
    },
  },
  mainAppbar: {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
  },
  menuOption: {
    fontSize: 13,
    marginRight: 0,
    textTransform: "uppercase",
    borderRadius: 0,
    padding: "7px 22px",
    color: theme.palette.other.white,
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      padding: "9px 15px",
    },
    "&:hover": {
      color: theme.palette.primary.blue,
    },
  },
  menuOptionMobile: {
    fontSize: 13,
    marginRight: 0,
    textTransform: "uppercase",
    borderRadius: 0,
    padding: "0px 25px",
    color: theme.palette.other.black,
    width: "100%",

    "&:hover": {
      color: theme.palette.primary.blue,
    },
  },
  menuSocialBtn: {
    fontSize: 13,
    padding: "5px 0px",
    width: "35px",
    minWidth: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "60px",
    },
  },
  mobileMenuOPen: {
    right: "0",
    top: "0",
  },
  Cross: {
    marginLeft: "auto",
  },
  height10: {
    height: "10px",
  },
  menToggle: {
    flexWrap: "wrap",
    display: "flex",
    padding: "10px",
    flexDirection: "column",
  },
  Toggle: {
    width: "30px",
    height: "2px",
    margin: "5px -15px",
    backgroundColor: "#fff",
  },
  facebook: {
    backgroundImage: `url(${Facebook})`,
    display: "block",
    height: "22px",
    width: "35px",
    backgroundRepeat: "no-repeat",
    "&:hover": {
      backgroundImage: `url(${FacebookW})`,
    },
  },
  instagram: {
    backgroundImage: `url(${Instagram})`,
    display: "block",
    height: "22px",
    width: "35px",
    backgroundRepeat: "no-repeat",
    "&:hover": {
      backgroundImage: `url(${InstagramW})`,
    },
  },
  vimeo: {
    backgroundImage: `url(${Vimeo})`,
    display: "block",
    height: "22px",
    width: "35px",
    backgroundRepeat: "no-repeat",
    "&:hover": {
      backgroundImage: `url(${VimeoW})`,
    },
  },
}));
