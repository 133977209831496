import React, { useEffect, useRef } from "react";
import {
  Typography,
  withWidth,
  Grid,
  Container,
  Hidden,
} from "@material-ui/core";
import { styles as useStyles } from "./styles";
import identityTLogoW from "../../../assets/images/identityTLogo.svg";
import DesktopVideo from "../../../assets/video/Desktop_AboutPage.mov";
import MobileVideo from "../../../assets/video/Mobile_AboutSS.mp4";

const FullWidthImage = (props) => {
  const classes = useStyles();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <Container className={classes.P0}>
      <Grid container direction="row" justify="center">
        <Grid item md={12}>
          <div className={classes.mainBanner}>
            <Grid container direction="row" justify="center">
              <Grid item md={11}>
                <div className={classes.relative}>
                  <Hidden smUp>
                    <video
                      playsInline
                      loop
                      autoPlay
                      muted
                      src={MobileVideo}
                      ref={videoEl}
                      className={classes.imgobject}
                    />
                  </Hidden>
                  <Hidden xsDown>
                    <video
                      playsInline
                      autoPlay
                      loop
                      muted
                      src={DesktopVideo}
                      ref={videoEl}
                      className={classes.imgobject}
                    />
                  </Hidden>
                  <Hidden xsDown>
                    <div className={classes.HeadRight}>
                      <Typography className={classes.para} component="div">
                        We are a New Delhi-based but globally minded design
                        consultancy dedicated to luxury spaces
                      </Typography>
                      <img
                        src={identityTLogoW}
                        alt=""
                        className={classes.Tlogo}
                      />
                    </div>
                  </Hidden>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withWidth()(FullWidthImage);
