import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import Press from "./pages/Press/Press";
import PressDetail from "./pages/PressDetail/PressDetail";
import Space from "./pages/Space/Space";
import SpaceDetail from "./pages/SpaceDetail/SpaceDetail";
import Object from "./pages/Object/Object";
import ObjectDetail from "./pages/ObjectDetail/ObjectDetail";
const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route exact path="/press">
          <Press />
        </Route>
        <Route path="/press/:id">
          <PressDetail />
        </Route>
        <Route exact path="/space">
          <Space />
        </Route>
        <Route path="/space/:id">
          <SpaceDetail />
        </Route>
        <Route exact path="/object">
          <Object />
        </Route>
        <Route path="/object/:id">
          <ObjectDetail />
        </Route>
      </Switch>
    </Router>
  );
};
export default MainRouter;
