import React from "react";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/About/Banner/Banner";
import FullWidthImage from "../../components/About/FullWidthImage/FullWidthImage";
import DetailText from "../../components/About/DetailText/DetailText";
import Quote from "../../components/About/Quote/Quote";
import Tabs from "../../components/About/Services/Tabs";
import Accordion from "../../components/About/Services/Accordion";
import ContactImage from "../../components/Common/ContactImage/ContactImage";
import { Hidden } from "@material-ui/core";

const About = () => {
  return (
    <div>
      <Banner />
      <Hidden smUp>
        <DetailText />
      </Hidden>
      <FullWidthImage />
      <Hidden xsDown>
        <DetailText />
      </Hidden>

      <Quote />
      <Hidden xsDown>
        <Tabs />
      </Hidden>
      <Hidden smUp>
        <Accordion />
      </Hidden>
      <ContactImage text="WE ARE DEVOTED TO APPLYING OUR PHILOSOPHY TO DESIGN. WE ARE HAPPY TO MEET YOU IN OUR APPOINTMENT BASED STUDIO IN NEW DELHI TO ENVISION YOUR SPACE" />
      <Footer />
    </div>
  );
};

export default About;
