import React from "react";
import Footer from "../../components/Footer/Footer";
import Video from "../../components/Home/Video/Video";
import Space from "../../components/Home/Space/Carousel";
import Brand from "../../components/Home/Brand/Brand";
import Perfection from "../../components/Home/Perfection/Perfection";
import Object from "../../components/Home/Object/Carousel";
import Featured from "../../components/Home/Featured/Featured";
import Press from "../../components/Home/Press/Carousel";
import { styles as useStyles } from "./styles";

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Video />
      <Space />
      <Brand />
      <Perfection />
      <Object />
      <Featured />
      <Press />
      <Footer />
    </div>
  );
};

export default Home;
