//import { Hidden } from "@material-ui/core";
const PressItem = ({ item, classes, onClick }) => {
  //const { picture, text } = item;
  const { picture } = item;

  return (
    <div className={classes.pressItem} onClick={onClick}>
      <img src={picture} alt="press" />
      {/* <Hidden xsDown>
      <div
        className={classes.pressText}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      </Hidden> */}
    </div>
  );
};
export default PressItem;
