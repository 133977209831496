export const styles = () => ({
  sliderArrow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    marginTop: 10,
    padding: "0px 0px 0px 0px",
  },
  slideBtn: {
    position: "relative !important",
    top: -50,
    left: 25,
    zIndex: 1,
    display: "inline-flex",
  },
  blankButton: {
    backgroundColor: "transparent",
    border: 0,
    cursor: "pointer",
    outline: "none !important",
    transition: "all linear 0.2s",
    minWidth: "70px",
    padding: "10px 0",
    "& img": {
      width: 70,
      minWidth: 70,
      height: 70,
    },
  },
  spaceContent: {
    fontSize: 15,
    textTransform: "uppercase",
    textAlign: "center",
    transition: "all linear 0.2s",
    display: "flex",
    justifyContent: "center",
    "& div": { marginTop: 10 },
    "& p": { marginTop: 10 },
  },
  spaceText: {
    marginBottom: "0px",
    "& p": {
      marginBottom: "10px",
    },
  },
  main: {
    padding: "0",
  },
  numberCount: {
    fontSize: 16,
    fontFamily: "Philosopher",
    marginTop: 0,
    marginRight: 40,
    letterSpacing: 2,
    minWidth: "52px",
  },
  p: {
    fontFamily: "Philosopher",
    color: "#000",
    lineHeight: "13px",
    fontSize: "14px",
    marginBottom: "0px",
    textAlign: "center",
    padding: "20px 40px",
  },
  positionRelative: {
    position: "relative",
  },
  overlayBottom: {
    position: "absolute",
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: "center",
  },
});
