import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  logo: {},
  fullLogoMain: {
    marginTop: "5px",
    marginBottom: "10px",
    textAlign: "center",
  },
}));
