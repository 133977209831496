import React from "react";
import { Grid, Container } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import Address from "../../components/Contact/Address/Address";
import Insta from "../../components/Common/InstagarmWidget/Insta";
import barberShop from "../../assets/images/barberShopDark.svg";
import identityTLogo from "../../assets/images/identityTLogoDark.svg";
import LogoCarousel from "../../components/Common/LogoCarousel/LogoCarousel";
import FullLogo from "../../components/Common/SanjytLogo/FullLogo";

import { styles as useStyles } from "./styles";
const contactImages = [
  { id: 1, src: identityTLogo },
  { id: 2, src: barberShop },
];
const Contact = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={2} xs={10}>
            <LogoCarousel images={contactImages} />
          </Grid>
          <Grid item md={8} xs={12}>
            <div className={classes.paddingTops}>
              <FullLogo />
              <Address />
            </div>
          </Grid>
        </Grid>
      </Container>

      <Insta />
      <Footer />
    </div>
  );
};

export default Contact;
