export const styles = () => ({
  inverted: {
    position: "relative",
    height: '417px',
  },
  topT: {
    position: "absolute",
    maxWidth: 190,
  },
  bottomT: {
    position: "absolute",
    top: 187,
    maxWidth: 190,
    left: 56,
  },
  invertedMobile: {
    marginTop: 30,
    position: "relative",
    left: 0,
    height: '417px',
    width: '260px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  topTMobile: {
    position: "absolute",
    maxWidth: 200,
  },
  bottomTMobile: {
    position: "absolute",
    top: 187,
    maxWidth: 200,
    left: 56,
  },
});
