import Logo from "../../../assets/images/logo-xxl.jpg";
import { styles as useStyles } from "./styles";
const FullWidthLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.fullLogoMain}>
      <img src={Logo} alt="Logo" className={classes.fulWidthLogo} />
    </div>
  );
};
export default FullWidthLogo;
