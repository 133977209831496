import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Container, Grid, Hidden, Link } from "@material-ui/core";
import InstaFeed1 from "../../../assets/images/instafeed/1.png";
import InstaFeed2 from "../../../assets/images/instafeed/2.png";
import InstaFeed3 from "../../../assets/images/instafeed/3.png";
import InstaFeed4 from "../../../assets/images/instafeed/4.png";
import InstaFeed5 from "../../../assets/images/instafeed/5.png";
import InstaFeed6 from "../../../assets/images/instafeed/6.png";
import InstaFeed7 from "../../../assets/images/instafeed/7.png";
import InstaFeed8 from "../../../assets/images/instafeed/8.png";
import InstaFeed9 from "../../../assets/images/instafeed/9.png";
import InstaFeed10 from "../../../assets/images/instafeed/10.png";
import InstaFeed11 from "../../../assets/images/instafeed/11.png";
import InstaFeed12 from "../../../assets/images/instafeed/12.png";
import InstaFeed13 from "../../../assets/images/instafeed/13.png";
import InstaFeed14 from "../../../assets/images/instafeed/14.png";
import InstaFeed15 from "../../../assets/images/instafeed/15.png";
import InstaFeed16 from "../../../assets/images/instafeed/16.png";
import InstaFeed17 from "../../../assets/images/instafeed/17.png";
import InstaFeed18 from "../../../assets/images/instafeed/18.png";
import InstaFeed19 from "../../../assets/images/instafeed/19.png";
import InstaFeed20 from "../../../assets/images/instafeed/20.png";
import InstaFeed21 from "../../../assets/images/instafeed/21.png";
import InstaFeed22 from "../../../assets/images/instafeed/22.png";
import InstaFeed23 from "../../../assets/images/instafeed/23.png";
import InstaFeed24 from "../../../assets/images/instafeed/24.png";
import ArrowLeft from "../../../assets/images/arrow-left.svg";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import { INSTAGRAM_URL } from "../../../utils/data";

export default class CenterMode extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 4,
      speed: 500,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 1200,
      afterChange: this.handleAfterChange,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div className="Instagram-Feed">
        <Container>
          <Grid container direction="row" justify="center">
            <Hidden xsDown>
              <Grid item md={1} sm={1}>
                <button
                  className="blank-button bt-prev white-arrow"
                  onClick={this.previous}
                >
                  <img alt="ArrowRight" src={ArrowRight} />
                </button>
              </Grid>
            </Hidden>
            <Grid item md={9} sm={10} xs={12}>
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed1" src={InstaFeed1} />
                      <img alt="InstaFeed2" src={InstaFeed2} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed3} />
                      <img alt="InstaFeed4" src={InstaFeed4} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed5" src={InstaFeed5} />
                      <img alt="InstaFeed6" src={InstaFeed6} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed7" src={InstaFeed7} />
                      <img alt="InstaFeed8" src={InstaFeed8} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed9} />
                      <img alt="InstaFeed4" src={InstaFeed10} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed11} />
                      <img alt="InstaFeed4" src={InstaFeed12} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed13} />
                      <img alt="InstaFeed4" src={InstaFeed14} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed15} />
                      <img alt="InstaFeed4" src={InstaFeed16} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed17} />
                      <img alt="InstaFeed4" src={InstaFeed18} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed19} />
                      <img alt="InstaFeed4" src={InstaFeed20} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed21} />
                      <img alt="InstaFeed4" src={InstaFeed22} />
                    </div>
                  </Link>
                </div>
                <div>
                  <Link
                    href={`${INSTAGRAM_URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="Instagram-img">
                      <img alt="InstaFeed3" src={InstaFeed23} />
                      <img alt="InstaFeed3" src={InstaFeed24} />
                    </div>
                  </Link>
                </div>
              </Slider>
            </Grid>
            <Hidden xsDown>
              <Grid item md={1} sm={1}>
                <button
                  className="blank-button bt-next white-arrow"
                  onClick={this.next}
                >
                  <img alt="ArrowLeft" src={ArrowLeft} />
                </button>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>
    );
  }
}
