import React, { useEffect, useRef } from "react";
import arrowRight from "../../../assets/images/arrow-left.svg";
import { Typography, Hidden } from "@material-ui/core";
const ObjectItem = ({ item, classes, onClick, arrow }) => {
  const { media, mediaHover, title, isVideo } = item;
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };
  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <div className={classes.objectDiv}>
      {!arrow ? (
        <div className={classes.objectItem} onClick={onClick}>
          {isVideo === 1 ? (
            <video
              playsInline
              loop
              autoPlay
              muted
              src={media}
              ref={videoEl}
              className={classes.imgobject}
            />
          ) : (
            <div>
              <Hidden smUp>
                <img src={mediaHover} alt="space" />
              </Hidden>
              <Hidden xsDown>
                <img src={media} alt="space" />
              </Hidden>
            </div>
          )}

          <div className={classes.overlayHeading}>
            <Typography className={classes.h2} component="h2">
              <div
                className={classes.pressText}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Typography>
          </div>
          <div className={classes.overlay}>
            {!arrow ? (
              <div className={classes.arrow}>
                <img className={classes.arrowRight} src={arrowRight} alt="" />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div onClick={onClick}>
          {isVideo === 1 ? (
            <video
              playsInline
              loop
              autoPlay
              muted
              src={media}
              ref={videoEl}
              className={classes.imgobject}
            />
          ) : (
            <div>
              <Hidden smUp>
                <img src={mediaHover} alt="space" />
              </Hidden>
              <Hidden xsDown>
                <img src={media} alt="space" />
              </Hidden>
            </div>
          )}

          <div className={classes.overlayHeading}>
            <Typography className={classes.h2} component="h2">
              <div
                className={classes.pressText}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Typography>
          </div>
          <div className={classes.overlay}>
            {!arrow ? (
              <div className={classes.arrow}>
                <img className={classes.arrowRight} src={arrowRight} alt="" />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default ObjectItem;
