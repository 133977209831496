import React, { useState } from "react";
import { AppBar, Toolbar, withWidth, Container, Link } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import { SCREEN_WIDTHS } from "../../utils/common";
import FacebookB from "../../assets/images/facebook-black.svg";
import VimeoB from "../../assets/images/vimeo-black.svg";
import InstagramB from "../../assets/images/instagram-black.svg";
import Cross from "../../assets/images/cross.png";
import { FACEBOOK_URL, INSTAGRAM_URL, VIMEO_URL } from "../../utils/data";

const Navbar = (props) => {
  const [anchorel, setanchorel] = useState(null);
  const { width } = props;
  const classes = useStyles();
  const handleMenuOpen = (event) => {
    setanchorel(event.currentTarget);
  };
  const renderDesktopMenu = () => {
    return (
      <div className={classes.menu}>
        <Link href="/" className={classes.menuOption}>
          Home
        </Link>
        <Link href="/about" className={classes.menuOption}>
          About
        </Link>
        <Link href="/space" className={classes.menuOption}>
          Spaces
        </Link>
        <Link href="/object" className={classes.menuOption}>
          Objects
        </Link>
        <Link href="/press" className={classes.menuOption}>
          Press
        </Link>
        <Link href="/contact" className={classes.menuOption}>
          Contact
        </Link>
      </div>
    );
  };
  const renderMobile = () => {
    return (
      <div
        id="menu-appbar"
        anchorel={anchorel}
        getcontentanchorel={null}
        anchororigin={{ vertical: "bottom", horizontal: "center" }}
        transformorigin={{ vertical: "top", horizontal: "center" }}
        open
        onClose={() => setanchorel(null)}
        className="menu-appbar"
      >
        <Link className={classes.Cross}>
          <img alt="cross" src={Cross} onClick={() => setanchorel(null)} />
        </Link>
        <Link href="/" className={classes.menuOptionMobile}>
          Home
        </Link>
        <Link href="/about" className={classes.menuOptionMobile}>
          About
        </Link>
        <div className={classes.height10}></div>
        <Link href="/space" className={classes.menuOptionMobile}>
          Spaces
        </Link>
        <Link href="/object" className={classes.menuOptionMobile}>
          Objects
        </Link>
        <div className={classes.height10}></div>
        <Link href="/press" className={classes.menuOptionMobile}>
          Press
        </Link>
        <Link href="/contact" className={classes.menuOptionMobile}>
          Contact
        </Link>
        <div className={classes.menuSocial}>
          <a href={`${FACEBOOK_URL}`} target="_blank" rel="noreferrer">
            <img
              alt="Facebook"
              src={FacebookB}
              className={classes.menuSocialBtn}
            />
          </a>
          <a href={`${INSTAGRAM_URL}`} target="_blank" rel="noreferrer">
            <img
              alt="Instagram"
              src={InstagramB}
              className={classes.menuSocialBtn}
            />
          </a>
          <a href={`${VIMEO_URL}`} target="_blank" rel="noreferrer">
            <img alt="Vimeo" src={VimeoB} className={classes.menuSocialBtn} />
          </a>
        </div>
      </div>
    );
  };
  const renderDesktopMenuSocial = () => {
    return (
      <div className={classes.menuSocial}>
        <a href={`${FACEBOOK_URL}`} target="_blank" rel="noreferrer">
          <span className={classes.facebook}></span>
        </a>
        <a href={`${INSTAGRAM_URL}`} target="_blank" rel="noreferrer">
          <span className={classes.instagram}></span>
        </a>
        <a href={`${VIMEO_URL}`} target="_blank" rel="noreferrer">
          <span className={classes.vimeo}></span>
          {/* <img alt="Vimeo" src={Vimeo} className={classes.menuSocialBtn} /> */}
        </a>
      </div>
    );
  };
  return (
    <AppBar className={classes.mainAppbar}>
      <Container>
        <Toolbar className={classes.toolbar}>
          <div className={classes.menu}>
            <span></span>
          </div>
          {width !== SCREEN_WIDTHS.XS && renderDesktopMenu()}
          {width === SCREEN_WIDTHS.XS ? (
            <div
              className={classes.menToggle}
              aria-label="more actions"
              onClick={handleMenuOpen}
              edge="end"
              color="inherit"
            >
              <span className={classes.Toggle}></span>
              <span className={classes.Toggle}></span>
            </div>
          ) : (
            renderDesktopMenuSocial()
          )}
          {anchorel && renderMobile()}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default withWidth()(Navbar);
