import { makeStyles } from '@material-ui/core/styles';
export const styles = makeStyles((theme) => ({
  main: {
    margin: '20px 0px',
    position: 'relative',
        backgroundColor: theme.palette.other.black,
         [theme.breakpoints.down('xs')]: {
      
          padding:'10px 0',
    },
  },
  section: {
    width: '100%',

    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    gap: 20,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      
      gap: 0,
    },

    '& span': {
      fontSize: 10,
      marginLeft: 150,
      [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      display:'block',
    },
    },
  },

  h2: {
    color: theme.palette.other.white,
    fontSize: "30px",
    lineHeight: "1.1",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: "0px",
    letterSpacing: "-1px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "50px",
    },
  },
  quoteImg: {
    // position: 'absolute',
    // left: 170,
    width: 50,
    height: 35,
    marginRight:"50px",
    // bottom: 30,
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 20,
      marginLeft: 0,
       marginRight:"0px",
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 30,
      height: 20,
      marginLeft: 0,
     
    },
     [theme.breakpoints.down('xs')]: {
      display:'none',
    },
  },
  nameImg: {
    top: "5px",
    position: 'relative',
     [theme.breakpoints.down('sm')]: {
     top: "0px",
    },
  },
}));
