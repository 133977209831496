import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { withStyles, Hidden, Typography } from "@material-ui/core";
import SpaceImg1 from "../../../assets/images/Bottom_image1.png";
import SpaceImg2 from "../../../assets/images/Bottom_image2.png";
import SpaceImg3 from "../../../assets/images/Bottom_image3.png";
import ArrowLeft from "../../../assets/images/arrow-left.svg";
import { styles } from "./styles";
class Carousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      fade: true,
      arrows: false,
      pauseOnHover: false,
      afterChange: this.handleAfterChange,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="space-root">
        <Slider
          ref={(c) => (this.slider = c)}
          {...settings}
          className="AboutSlider"
        >
          <div>
            <img src={SpaceImg1} alt="Space1" />
            <Hidden xsDown>
              <Typography className="paragraph" style={{ marginTop: 10 }}>
                We take pride in <strong> creating spaces</strong> that are a
                visual delight yet functional.
              </Typography>
            </Hidden>
          </div>
          <div>
            <img src={SpaceImg2} alt="Space2" />
            <Hidden xsDown>
              <Typography className="paragraph" style={{ marginTop: 10 }}>
                We take pride in <strong> creating spaces</strong> that are a
                visual delight yet functional.
              </Typography>
            </Hidden>
          </div>
          <div>
            <img src={SpaceImg3} alt="Space3" />
            <Hidden xsDown>
              <Typography className="paragraph" style={{ marginTop: 10 }}>
                We take pride in <strong> creating objects</strong> that are a
                visual delight yet functional.
              </Typography>
            </Hidden>
          </div>
        </Slider>
        <div className="AboutCarousel-arrow">
          <button className="blank-button bt-next" onClick={this.next}>
            <Hidden xsDown>
              <img alt="ArrowLeft" src={ArrowLeft} />
            </Hidden>
          </button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Carousel);
