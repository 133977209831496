import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  main: {
    overflow: "hidden",
  },
  loader: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: 111,
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
  },
}));
