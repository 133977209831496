import React, { useEffect, useState } from "react";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import SanjytLogo from "../../components/Common/SanjytLogo";
// import DetailBanner from "../../components/Press/DetailBanner/DetailBanner";
// import DetailText from "../../components/Press/DetailText/DetailText";
import Quote from "../../components/Press/Quote/Quote";
import ContactImage from "../../components/Common/ContactImage/ContactImage";
import PressCarousel from "../../components/Press/PressCarousel/PressCarousel";
import { withRouter } from "react-router-dom";
import { PressListContent } from "../../utils/data";
const styles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    overflow: "hidden",
  },
}));

const PressDetail = ({ location }) => {
  const classes = styles();
  const [pressItem, setPressItem] = useState({});
  useEffect(() => {
    window.scroll(0, 0);
    const press = PressListContent.find(
      (item) => item.id === location.state.id
    );
    setPressItem(press);
  }, []);
  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={2} xs={10}>
            <SanjytLogo />
          </Grid>

          <Grid item md={9} xs={12}>
            <PressCarousel item={pressItem} />
            {/* <DetailBanner detail={pressItem} /> */}
          </Grid>
        </Grid>
      </Container>
      <Quote />
      {/* <PressCarousel item={pressItem} /> */}
      {/* <DetailText item={pressItem} /> */}
      <ContactImage
        text="members of the press, producers, fellow designers, and makers of all
        types are encouraged to contact us"
      />
      <Footer />
    </div>
  );
};
export default withRouter(PressDetail);
