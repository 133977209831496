import React, { useEffect, useState } from "react";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import SanjytLogo from "../../components/Common/SanjytLogo";
import SpaceCarousel from "../../components/Space/SpaceCarousel/SpaceCarousel";
import FullWidthImage from "../../components/Space/FullWidthImage/FullWidthImage";
import DetailFullImage from "../../components/Space/DetailFullImage/DetailFullImage";
import DetailText from "../../components/Space/DetailText/DetailText";
import FeaturedQuote from "../../components/Space/FeaturedQuote/FeaturedQuote";
import { withRouter } from "react-router-dom";
import { SpaceListContent } from "../../utils/data";
const styles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    overflow: "hidden",
  },
}));

const SpaceDetail = ({ location }) => {
  const classes = styles();
  const [spaceItem, setSpaceItem] = useState({});
  useEffect(() => {
    window.scroll(0, 0);
    const space = SpaceListContent.find(
      (item) => item.id === location.state.id
    );
    setSpaceItem(space);
  }, []);
  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={2} xs={10}>
            <SanjytLogo />
          </Grid>
          <Grid item md={9} xs={12}>
            <SpaceCarousel item={spaceItem} />
          </Grid>
        </Grid>
      </Container>

      <DetailText item={spaceItem} />
      <FullWidthImage item={spaceItem} />
      <FeaturedQuote item={spaceItem} />
      <DetailFullImage item={spaceItem} />
      <Footer />
    </div>
  );
};
export default withRouter(SpaceDetail);
