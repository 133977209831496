import { styles as useStyles } from "./styles";
import { Typography, Container } from "@material-ui/core";
const FeaturedQuote = ({ item: { featuredLogo } }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      {featuredLogo && (
        <Container>
          <div className={classes.section}>
            <Typography className={classes.h2} variant="h2">
              FEATURED AS IN
              <span className={classes.featuredImage}>
                <img
                  src={featuredLogo}
                  alt="logo2"
                  className={classes.nameImg}
                />
              </span>
            </Typography>
          </div>
        </Container>
      )}
    </div>
  );
};
export default FeaturedQuote;
