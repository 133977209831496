import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import service1 from "../../../assets/images/services/mobile/service1.jpg";
import service2 from "../../../assets/images/services/mobile/service2.jpg";
import service3 from "../../../assets/images/services/mobile/service3.jpg";
import service4 from "../../../assets/images/services/mobile/service4.jpg";
import service5 from "../../../assets/images/services/mobile/service5.jpg";
import service6 from "../../../assets/images/services/mobile/service6.jpg";
import service7 from "../../../assets/images/services/mobile/service7.jpg";
import service8 from "../../../assets/images/services/mobile/service8.jpg";
import { styles as useStyles } from "./styles";
import { AboutTabValues } from "../../../utils/data";
export default function CustomizedAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderServiceImage = (value) => {
    switch (value) {
      case 0:
        return <img src={service1} alt="service1" />;
      case 1:
        return <img src={service2} alt="service2" />;
      case 2:
        return <img src={service3} alt="service3" />;
      case 3:
        return <img src={service4} alt="service4" />;
      case 4:
        return <img src={service5} alt="service5" />;
      case 5:
        return <img src={service6} alt="service6" />;
      case 6:
        return <img src={service7} alt="service7" />;
      case 7:
        return <img src={service8} alt="service8" />;

      default:
        return <img src={service1} alt="service1" />;
    }
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.p} gutterBottom>
        A lot of design is story telling. We believe in writing stories. Stories
        that are based on client brief. Stories that are expressed through
        different mediums. The Studio’s expertise is applied to a range of
        services from residential to retail, corporate to commercial. Even
        though we design spaces, our involvement crosses over different
        disciplines of designs mentioned below, combined with our strengths to
        bring sketches to reality.
      </Typography>

      {AboutTabValues.map(({ id, label }) => {
        return (
          <Accordion
            key={`service-${id}`}
            square
            expanded={expanded === `panel${id}`}
            onChange={handleChange(`panel${id}`)}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              aria-controls={`panel${id}d-content`}
              id={`panel${id}-header`}
              className={classes.AccordionSummary}
            >
              <Typography className={classes.tabBtnMobile}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>{renderServiceImage(id)}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
