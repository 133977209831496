import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  root: {
    marginTop: "-40px",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },

  h5: {
    marginBottom: "0",
    fontSize: "13px",
    fontWeight: "normal",
    marginTop: "0",
    lineHeight: "22px",
    fontFamily: "Be Vietnam",
    "& a": {
      color: "#000000",
      textDecoration: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "16px",
      textAlign: "center",
    },
  },
  h3: {
    fontSize: "17px",
    fontFamily: "Be Vietnam",
    fontWeight: "600",
    "& a": {
      color: "#000000",
      textDecoration: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "23px",
      textAlign: "center",
    },
  },
  p: {
    textTransform: "uppercase",
    lineHeight: "16px",
    fontSize: "13px",
    marginBottom: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "12px",
      textAlign: "center",
    },
  },
  height: {
    height: "10px",
  },
  padding: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
}));
