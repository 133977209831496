import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Container, Grid, withStyles, Hidden } from "@material-ui/core";
import ArrowLeft from "../../../assets/images/arrow-left.svg";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import WOW from "wowjs";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { styles } from "./styles";
//import KnowMorebtn from "../../Common/Button/KnowButton";
class PressCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 1,
      speed: 500,
      arrows: false,
      dots: false,
      autoplay: false,
      autoplaySpeed: 3000,
      afterChange: this.handleAfterChange,
    };
    const { classes, item } = this.props;
    return (
      <div className={classes.main}>
        <div>
          <Container>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={10} xs={12}>
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                  {item &&
                    item.magzine &&
                    item.magzine.map((n, index) => {
                      return (
                        <div key={item.id}>
                          <div className="p-3">
                            <Zoom>
                              <img alt={item.title} src={n.src} />
                            </Zoom>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
                {item && item.link && (
                  <div className="itemlink">
                    <div className="slide-btn">{/* <KnowMorebtn /> */}</div>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {item.link}
                    </a>
                  </div>
                )}

                {item.magzine && item.magzine.length > 1 && (
                  <Hidden xsDown>
                    <div className="sliderArrow">
                      <button
                        className="blank-button bt-prev"
                        onClick={this.previous}
                      >
                        <img alt="ArrowRight" src={ArrowRight} />
                      </button>
                      <button
                        className="blank-button bt-next"
                        onClick={this.next}
                      >
                        <img alt="ArrowLeft" src={ArrowLeft} />
                      </button>
                    </div>
                    <div className="numberCount">
                      {this.state.currentSlide + 1}/{" "}
                      {item.magzine && item.magzine.length}
                    </div>
                  </Hidden>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(PressCarousel);
