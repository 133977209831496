import React from "react";
import Navbar from "./components/Navbar/Navbar";
import MainRouter from "./router";
const MainApp = () => {
  return (
    <>
      <Navbar />
      <MainRouter />
    </>
  );
};

export default MainApp;
