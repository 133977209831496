import { makeStyles } from "@material-ui/core/styles";
export const styles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 40,
    [theme.breakpoints.down("xs")]: {
      paddingTop: "20px",
      paddingBottom: 0,
    },
  },

  videoCard: {
    boxShadow: "none",
    borderRadius: 0,
  },
  h2: {
    color: theme.palette.other.black,
    fontSize: "45px",
    lineHeight: "36px",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: 15,
    letterSpacing: "-1px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "45px",
      lineHeight: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "55px",
      lineHeight: "56px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "66px",
      lineHeight: "60px",
    },
  },
  Quote: {
    [theme.breakpoints.down("xs")]: {
      width: "60px",
    },
  },

  p: {
    textTransform: "uppercase",
    lineHeight: "16px",
    fontFamily: "Be Vietnam",
    letterSpacing: 0,
    fontSize: "11px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  paddingLeft: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  blue: {
    color: theme.palette.primary.blue,
  },
  margin: {
    height: 60,
    [theme.breakpoints.down("xs")]: {
      height: 20,
    },
  },
  padding: {
    paddingTop: "50px",
    paddingBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
      paddingBottom: "20px",
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",

    "& img": {
      width: 100,
      position: "relative",
      right: -8,
      bottom: 49,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
}));
