import React, { useEffect, useState } from "react";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
//import ContactImage from "../../components/Common/ContactImage/ContactImage";
import SanjytLogo from "../../components/Common/SanjytLogo";
import ObjectCarousel from "../../components/Object/ObjectCarousel/ObjectCarousel";
import { withRouter } from "react-router-dom";
import { ObjectListContent } from "../../utils/data";
const styles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    overflow: "hidden",
  },
}));

const ObjectDetail = ({ location }) => {
  const classes = styles();
  const [objectItem, setObjectItem] = useState({});
  useEffect(() => {
    window.scroll(0, 0);
    const object = ObjectListContent.find(
      (item) => item.id === location.state.id
    );
    setObjectItem(object);
  }, []);
  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={2} xs={10}>
            <SanjytLogo />
          </Grid>
          <Grid item md={9} xs={12}>
            <ObjectCarousel item={objectItem} />
          </Grid>
        </Grid>
      </Container>
      <div className="carobj"></div>
      {/* <ContactImage
        text="members of the press, producers, fellow designers, and makers of all
        types are encouraged to contact us"
      /> */}
      <Footer />
    </div>
  );
};
export default withRouter(ObjectDetail);
