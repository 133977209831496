import { styles as useStyles } from "./styles";
import { Typography } from "@material-ui/core";
const Quote = ({ detail }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div className={classes.section}>
        <Typography className={classes.h2} variant="h2">
          We believe in writing stories.
        </Typography>
      </div>
    </div>
  );
};
export default Quote;
