import { makeStyles } from "@material-ui/core/styles";
export const styles = makeStyles((theme) => ({
  mainBanner: {
    position: "relative",
    marginTop: "36px",
  },

  P0: {
    paddingTop: "50px",
    paddingBottom: "70px",
    position: "relative",
  },
  DFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  imgobject: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
  },
  text: {
    height: "100%",
    color: theme.palette.other.black,
    display: "flex",
    alignItems: "center",
    fontSize: 30,
    lineHeight: "35px",
    textTransform: "uppercase",
    maxWidth: 790,
    left: "0",
    right: "0",
    margin: "auto",
    position: "relative",
    fontFamily: "Philosopher",
    fontWeight: 400,
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: 21,
      padding: 10,
      justifyContent: "center",
      width: "100%",
      height: "100%",
      left: 0,
    },
  },
  arrow: {
    width: 70,
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      width: 50,
    },
  },
}));
