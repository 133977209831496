export const styles = () => ({
  inverted: {
    position: "relative",
  },
  topT: {
    position: "absolute",
    width: 190,
  },
  bottomT: {
    position: "absolute",
    top: 187,
    width: 190,
    left: 56,
  },
  invertedMobile: {
    marginTop: 30,
    position: "relative",
    left: 83,
  },
  topTMobile: {
    position: "absolute",
    width: 190,
  },
  bottomTMobile: {
    position: "absolute",
    top: 187,
    width: 190,
    left: 56,
  },
});
