import { makeStyles } from '@material-ui/core/styles';
export const styles = makeStyles((theme) => ({
  main: {
    margin: '20px 0px',
    position: 'relative',
  },
  section: {
    width: '100%',
    backgroundColor: theme.palette.other.black,
    textAlign: 'center',
    padding:'7px 0',
  },
 h2: {
    color: theme.palette.other.white,
    fontSize: "30px",
    lineHeight: "30px",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: "0px",
    letterSpacing: "-1px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px",
      lineHeight: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "50px",
      lineHeight: "45px",
    },
  },
}));
