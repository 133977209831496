import { useEffect } from "react";
import PressItem from "./PressItem";
import { styles as useStyles } from "./styles";
import { Grid, Typography } from "@material-ui/core";
import owl from "../../../assets/images/press/ullu.png";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import WOW from "wowjs";
const PressList = ({
  list,
  hideIndex = null,
  isVideo = false,
  noPadding = false,
  history,
}) => {
  const classes = useStyles();
  const handlePressClick = (id) => {
    history.push({ pathname: `/press/${id}`, state: { id } });
  };
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div>
      <Grid
        container
        spacing={2}
        className={
          noPadding ? classes.containerMainNoTopPadding : classes.containerMain
        }
      >
        {list.map((pressItem, index) => {
          if (hideIndex && index === hideIndex) {
            if (!isVideo) {
              return (
                <Grid item md={4} sm={4} xs={4} key={index}>
                  <div className={classes.imgContainer}>
                    <div
                      className={classes.textCenter}
                      style={{ position: "relative", zIndex: 1 }}
                    >
                      <Typography className={classes.textName} component="div">
                        sanjyt syngh
                      </Typography>
                      <Typography className={classes.textX} component="div">
                        X
                      </Typography>
                      <Typography className={classes.textName} component="div">
                        scarlet <br /> splendour
                      </Typography>
                    </div>
                    <img
                      src={owl}
                      alt="collabImg"
                      className={`${classes.owl} wow slideInDown`}
                      data-wow-duration="2s"
                      data-wow-delay=".1s"
                      style={{ position: "relative", zIndex: 2 }}
                    />
                  </div>
                </Grid>
              );
            }
            // return (
            //   <Grid
            //     item
            //     md={4}
            //     sm={4}
            //     xs={4}
            //     className={classes.videoBox}
            //     key={index}
            //   >
            //     <div
            //       style={{
            //         background: "#000000",
            //         width: "100%",
            //         height: "86%",
            //       }}
            //     ></div>
            //     <div className={classes.dot}></div>
            //   </Grid>
            // );
          }
          return (
            <Grid item md={4} sm={4} xs={4} key={index}>
              <PressItem
                item={pressItem}
                key={pressItem.id}
                classes={classes}
                onClick={() => handlePressClick(pressItem.id)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

PressList.propTypes = {
  list: PropTypes.array.isRequired,
  hideIndex: PropTypes.number,
  isVideo: PropTypes.bool,
  noPadding: PropTypes.bool,
};
export default withRouter(PressList);
