import { Typography } from "@material-ui/core";
import { styles as useStyles } from "./styles";
import arrowRight from "../../../assets/images/arrow-left.svg";
import PropTypes from "prop-types";
const ContactImage = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.contactImageContainer}>
      <Typography className={classes.text} component="div">
        {text}
        <a href="/contact">
          <img src={arrowRight} alt="arrowRight" className={classes.arrow} />
        </a>
      </Typography>
    </div>
  );
};
ContactImage.propTypes = {
  text: PropTypes.string.isRequired,
};
export default ContactImage;
