import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
    paddingTop: 0,
    paddingBottom: 40,
    position: "relative",
     [theme.breakpoints.down("xs")]: {
       paddingBottom: 0,
    },
    
  },

  AboutImg:{
    maxWidth:'330px',
    position:'relative',
    width:'100%',
    zIndex:'1',
    [theme.breakpoints.down("xs")]: {
      maxWidth:'80vw',
    },
  },
  h2: {
    color: theme.palette.other.black,
    fontSize: "30px",
    lineHeight: "30px",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: "40px",
    letterSpacing: "-1px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px",
      lineHeight: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "50px",
      lineHeight: "45px",
    },
  },
  padding:{
    paddingTop:'50px',
    paddingLeft:'20px',
    position:'relative',
      height:'100%',
  },
  rabitFull:{
    position:'absolute',
    bottom:'0',
    left:'0',
    right:'0',
    margin:'auto',

  },
  marginTop:{
    marginTop:'20px',
     [theme.breakpoints.down("xs")]: {
       marginTop: "0px",
    },
  },
    p: {
    textTransform: "uppercase",
    lineHeight: "16px",
    fontFamily: "Be Vietnam",
    letterSpacing:0,
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
}));
