import { makeStyles } from "@material-ui/core/styles";
import Image from "../../../assets/images/CTA_blue.jpg";
import ImageHover from "../../../assets/images/CTA_blue_HOVER.jpg";

export const styles = makeStyles((theme) => ({
  Button: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    //backgroundColor: theme.palette.primary.blue,
    color: "#fff",
    textTransform: "uppercase",
    borderRadius: "0",
    padding: "10px 10px 9px 10px",
    //fontSize: "18px",
    fontSize: "0px",
    lineHeight: "17px",
    fontWeight: "400",
    fontFamily: "Be Vietnam",
    transition: "all linear 0.2s",
    display: "true",
    alignItems: "flex-end",
    justifyContent: "start",
    textAlign: "left",
    margin: "auto",
    width: "103px",
    height: "79px",

    "&:hover": {
      // backgroundColor: theme.palette.primary.blue,
      // letterSpacing: "4px",
      backgroundImage: `url(${ImageHover})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },

    [theme.breakpoints.down("xs")]: {
      width: "67px",
      height: "52px",
      fontSize: "0px",
      lineHeight: "13px",
      "&:hover": {
        letterSpacing: "2px",
      },
    },
  },

  root: {},
}));
