import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Container, Grid, Hidden } from "@material-ui/core";
import SpaceImg1 from "../../../assets/images/projects/project1.jpg";
import SpaceImg2 from "../../../assets/images/projects/project2.jpg";
import SpaceImg3 from "../../../assets/images/projects/project3.jpg";
import SpaceImg4 from "../../../assets/images/projects/project4.png";
import SpaceImg5 from "../../../assets/images/projects/project5.jpg";
import SpaceImg6 from "../../../assets/images/projects/project6.jpg";
import SpaceImg7 from "../../../assets/images/projects/project7.jpg";
import SpaceImg8 from "../../../assets/images/projects/project8.jpg";
import SpaceImg9 from "../../../assets/images/projects/project9.jpg";
import SpaceImg10 from "../../../assets/images/projects/project10.jpg";
import ArrowLeft from "../../../assets/images/arrow-left.svg";
import ArrowRight from "../../../assets/images/arrow-right.svg";
import KnowMorebtn from "../../Common/Button/KnowButton";
import DoNotCross from "../../../assets/images/DONOT-CROSS.gif";
import WOW from "wowjs";

export default class CenterMode extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      currentSlide: 0,
    };
  }
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  handleAfterChange = (index) => {
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: 3,
      speed: 500,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 3000,
      afterChange: this.handleAfterChange,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div className="space-root">
        <div className="do-not-cross">
          <img alt="DoNotCross" src={DoNotCross} />
        </div>
        <div className="space-slider">
          <Container>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Hidden xsDown>
                <Grid item md={10} align="center">
                  <h2 className="main-heading">spaces</h2>
                </Grid>
              </Hidden>
              <Grid item md={9} xs={12}>
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg1} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Epiphany Blue, New Delhi
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0.5s"
                    >
                      <img alt="SpaceImg2" src={SpaceImg2} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Urban Chalet, New Delhi
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0.10s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg3} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Bespoke Penthouse, Mumbai
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.15s"
                    >
                      <img alt="SpaceImg2" src={SpaceImg4} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Bachelor Apartment, New Delhi
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg5} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Dubai Holiday Villa, Dubai
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg6} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Eclectic Apartment, New Delhi
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg7} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Luxury Corporate Office, Mumbai
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg8} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Luxury Penthouse, Mumbai
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg9} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Bespoke Luxury Office, Mumbai
                        </div>
                      </Hidden>
                    </div>
                  </div>
                  <div>
                    <div
                      className="p-3 wow fadeInDown"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <img alt="SpaceImg1" src={SpaceImg10} />
                      <Hidden xsDown>
                        <div className="space-content">
                          Gold's Gym, Sec 14, Gurugram
                        </div>
                      </Hidden>
                    </div>
                  </div>
                </Slider>
                <Hidden xsDown>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item sm={8}></Grid>
                    <Grid item sm={4}>
                      <a href="/space">
                        <div className="slide-btn">
                          <KnowMorebtn />
                        </div>
                      </a>
                    </Grid>
                  </Grid>
                </Hidden>
                <div className="slider-arrow">
                  <button
                    className="blank-button bt-prev"
                    onClick={this.previous}
                  >
                    <img alt="ArrowRight" src={ArrowRight} />
                  </button>
                  <button className="blank-button bt-next" onClick={this.next}>
                    <img alt="ArrowLeft" src={ArrowLeft} />
                  </button>
                </div>
                <div className="number-count">
                  {this.state.currentSlide + 1}/10
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
