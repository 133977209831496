import React from "react";
import { withWidth, Button } from "@material-ui/core";
import { styles as useStyles } from "./styles";
const KnowButton = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button className={`${classes.Button} customSafari`}>
        know <br /> more
      </Button>
    </div>
  );
};
export default withWidth()(KnowButton);
