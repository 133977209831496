import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Featured from "../../../assets/images/featured.png";

export default class CenterMode extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <div className="featured-slider">
        <Slider {...settings}>
          <div className="feat-img">
            <img src={Featured} alt="Featured1" />
          </div>
          <div className="feat-img">
            <img src={Featured} alt="Featured2" />
          </div>
          <div className="feat-img">
            <img src={Featured} alt="Featured3" />
          </div>
          <div className="feat-img">
            <img src={Featured} alt="Featured4" />
          </div>
          <div className="feat-img">
            <img src={Featured} alt="Featured5" />
          </div>
        </Slider>
      </div>
    );
  }
}
