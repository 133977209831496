import { makeStyles } from "@material-ui/core/styles";
export const styles = makeStyles((theme) => ({
  main: {
    margin: "20px 0px",
    position: "relative",
    backgroundColor: theme.palette.other.black,
  },
  section: {
    width: "100%",

    textAlign: "center",
    position: "relative",
    display: "flex",
    gap: 20,
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      gap: 0,
    },

    "& span": {
      fontSize: 10,
      marginLeft: 30,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 5,
      },
    },
  },

  h2: {
    color: theme.palette.other.white,
    fontSize: "30px",
    lineHeight: "1",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: "Staatliches",
    marginBottom: "5px",
    marginTop: "5px",
    marginRight: "100px",
    letterSpacing: "-1px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  quoteImg: {
    // position: 'absolute',
    // left: 170,
    width: 50,
    height: 35,
    marginRight: "50px",
    // bottom: 30,
    [theme.breakpoints.down("sm")]: {
      width: 30,
      height: 20,
      marginLeft: 0,
      marginRight: "0px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 30,
      height: 20,
      marginLeft: 0,
    },
  },
  nameImg: {
    top: "5px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      top: "0px",
    },
  },
  customText: {
    marginRight: "50px",
  },
}));
